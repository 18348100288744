import React, {useEffect, useState} from 'react';

import {fetchProducts, PRODUCTS_PAGE_COUNT} from '../Products/actions';

import "./style.css"
import {useSelector} from "react-redux";
import {getProducts, getProductsFetching, getProductsHasMore} from "../Products/reducer";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import GlobalIcon from "../../icons/global-icon.png";
import PartnerOffers from "../../icons/partner-offers.png";
import MarketEntry from "../../icons/market-entry.png";
import { ReactComponent as ArrowIcon } from "../../icons/arrow-icon.svg";

import InfiniteView from "../../components/InfiniteView";
import GradientBox from "../../components/GradientBox/gradientBox";
import {getCompanies, getCompaniesFetching} from "../CongressOnline/reducer";
import {BrowserView, MobileView} from "react-device-detect";
import CompanyCard from "../CongressOnline";
import {COMPANIES_PAGE_COUNT, fetchCompanies} from "../CongressOnline/actions";
import config from "../../config/config";
import translations from "../../components/LanguageModal/translations";
import {getLanguage} from "../../components/LanguageModal/reducer";
import Products from "../Products";
import {Link} from "react-router-dom";
import ProductsContainer from "../../components/ProductsContainer";
import CatalogFilterSection from "../Catalog/catalogFilterSection";
import {fetchCatalogue} from "../Categories/actions";
import {getCategories} from "../Categories/reducer";

export default function Home (props) {
    const {dispatch,
        userData,
        userLocation,
        mixpanel,
        routeAction,
        isPageReloaded,
        navigate,
    } = props;

    const loadMore = () => readCompanies(Math.round(companies.length / COMPANIES_PAGE_COUNT) + 1);
    const loading = useSelector((state) => getProductsFetching(state.products));
    const products = useSelector((state) => getProducts(state.products));
    const hasMore = useSelector((state) => getProductsHasMore(state.products));
    const companies = useSelector((state) => getCompanies(state.companies));
    const isFetching = useSelector((state) => getCompaniesFetching(state.companies));
    const categories = useSelector(state => getCategories(state.categories));



    const MAX_PRODUCTS_TO_DISPLAY = 4;
    const animalHusbandryCategoryId = 193;
    const cropProductionCategoryId = 197;
    const techniqueProductsId = 198;
    const manufacturingProductsId = 199;
    const anotherProductsId = 223;

    const animalHusbandryProducts = categories.find(cat => cat.cat_ID === 193)?.category_count || 0;
    const cropProductionProducts = categories.find(cat => cat.cat_ID === 197)?.category_count || 0;
    const techniqueProducts = categories.find(cat => cat.cat_ID === 198)?.category_count || 0;
    const manufacturingProducts = categories.find(cat => cat.cat_ID === 199)?.category_count || 0;
    const anotherProducts = categories.find(cat => cat.cat_ID === 223)?.category_count || 0;

    const wordsArray = [
        "товар",
        "товара",
        "товаров"
    ]

    function NormalizeCountForm(number, words_arr) {
        number = Math.abs(number);
        if (Number.isInteger(number)) {
            let options = [2, 0, 1, 1, 1, 2];
            return words_arr[(number % 100 > 4 && number % 100 < 20) ? 2 : options[(number % 10 < 5) ? number % 10 : 5]];
        }
        return words_arr[1]
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if ( routeAction === 'PUSH' || isPageReloaded ) {

            setWindowScrollPosition();
        }
        if (mixpanel)
            mixpanel.track('Products List View', { ...userLocation, ...userData });
    }, []);



    useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
    }, [props]);

    useEffect(() => {
        readCompanies(1);
        readCatalogue();
    }, []);

    const readCompanies = (page) => {
        dispatch(fetchCompanies(
            {
                page,
                per_page: COMPANIES_PAGE_COUNT,
                order: 'asc',
                orderby: 'id',
                event: 'ASIAEXPO 2024'
            }
        ));
    }

     function  loadProducts() {
       const nextPage = Math.round(products.length / PRODUCTS_PAGE_COUNT) + 1;
       readProducts(nextPage);
      }

      function readProducts(page) {
        dispatch(fetchProducts({
          page,
          order: 'asc',
          orderby: 'title',
            home_category: config.CATALOG_CATEGORIES_ID,
        }));
      }

    const readCatalogue = () => {
        dispatch(fetchCatalogue(196, { props }));

        const allProductIds = categories.reduce((acc, category) => {
            if (category.products_ids) {
                return acc.concat(category.products_ids);
            }
            return acc;
        }, []);

        // console.log(allProductIds);

        allProductIds.forEach(id => {
            dispatch(fetchProducts({
                page: 1,
                id: id
            }));
        });
    };

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const [animalHusbandryFilter, setAnimalHusbandryFilter] = useState(null);
    const [cropProductionFilter, setCropProductionFilter] = useState(null);


    const handleAnimalHusbandryFilterChange = (filterId) => {
        setAnimalHusbandryFilter(filterId);
    };

    const handleCropProductionFilterChange = (filterId) => {
        setCropProductionFilter(filterId);
    };

    const animalHusbandryFilters = [
        { id: 195, label: "Корм для МРС" },
        { id: 194, label: "Корм для КРС" },
        { id: 207, label: t('pigFood') },
        { id: 206, label: t('birdFood') },
        { id: 208, label: 'Корм для коней' },
    ];

    const cropProductionFilters = [
        { id: 200, label: t('fertilizers') },
        { id: 201, label: t('additives')  },
        { id: 205, label: 'Оборудование для переработки зерна'  },
        { id: 203, label: 'Семена'  },
    ];


    const SupplierCard = () => {
        return (
            <>

                <BrowserView>
                    <div className="suppliers-container">
                        {companies ? companies.slice(0, 6).map((company) => (
                        <div className="supplier-card" onClick={() => navigate(`congress-online/company/${company.id}`)}>
                            <img src={company.logo} className='supplier-logo' />
                        </div>
                        )) : ""}
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="suppliers-container mobile">
                        {companies ? companies.slice(0, 4).map((company) => (
                            <div className="supplier-card mobile" onClick={() => navigate(`congress-online/company/${company.id}`)}>
                                <img src={company.logo} className='supplier-logo mobile'/>
                            </div>
                        )) : ""}
                    </div>
                </MobileView>
            </>
        )

    }


    const BestOffers = () => {
        return (
            <>
                <BrowserView>
                    <div className='best-offers'>
                        <div className='best-offer-card'>
                            <span className='offer-title'>СЗР и семена от протектфида!</span>
                            <span className='offer-text'>со скидкой для первых клиентов</span>
                        </div>
                        <div className='best-offer-card'>
                            <span className='offer-title'>СЗР и семена от протектфида!</span>
                            <span className='offer-text'>со скидкой для первых клиентов</span>
                        </div>
                        <div className='best-offer-card'>
                            <span className='offer-title'>СЗР и семена от протектфида!</span>
                            <span className='offer-text'>со скидкой для первых клиентов</span>
                        </div>
                    </div>
                </BrowserView>

                <MobileView>
                    <div className='best-offers mobile'>
                        <div className='best-offer-card mobile'>
                            <span className='offer-title mobile'>СЗР и семена от протектфида!</span>
                            <span className='offer-text mobile'>со скидкой для первых клиентов</span>
                        </div>
                        <div className='best-offer-card mobile'>
                            <span className='offer-title mobile'>СЗР и семена от протектфида!</span>
                            <span className='offer-text mobile'>со скидкой для первых клиентов</span>
                        </div>
                    </div>
                </MobileView>
            </>
        )
    }



    return (
        <>
            <BrowserView>
                <div className='venue-gradient-box'>
                    <GradientBox/>
                </div>

                <div className='venue-home-title'>
                    {t('workingInTeam')}
                </div>
                <div className='asiaexpo-venue-title'>
                    ASIAEXPO VENUE
                </div>
                <div className='static-home-box'>
                    <div className='static-home-block'>
                        <img src={PartnerOffers} className='static-home-box-image'/>
                        <p>Лучшие предложения партнеров из разных стран</p>
                    </div>
                    <div className='static-home-block'>
                        <img src={GlobalIcon} className='static-home-box-image'/>
                        <p>Комфортный выход на рынки Глобального Юга</p>
                    </div>
                    <div className='static-home-block'>
                        <img src={MarketEntry} className='static-home-box-image'/>
                        <p>Доступно во всех странах</p>
                    </div>
                </div>
                <div className='title-for-suppliers first-section'>
                    <span className='text-congress-online companies home'>{t('ourSuppliers')}</span>
                    <div className='in-congress-online' onClick={() => navigate(`/congress-online`)}>
                        <span>В Конгресс-онлайн</span>
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                </div>
                <SupplierCard/>

                <div className='title-for-suppliers'>
                    <div className='text-segment companies home segment'
                         onClick={() => navigate(`/catalog/animal-husbandry`)}>
                        Животноводство
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{animalHusbandryProducts} {NormalizeCountForm(animalHusbandryProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={animalHusbandryCategoryId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Продукты животноводства отсутствуют "}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment'
                         onClick={() => navigate(`/catalog/plant-production`)}>
                        Растениеводство
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{cropProductionProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={cropProductionCategoryId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Продукты растениеводства отсутствуют "}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment' onClick={() => navigate(`/technique`)}>
                        Техника
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{techniqueProducts} {NormalizeCountForm(techniqueProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={techniqueProductsId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Продукты техники отсутствуют"}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment'>
                        Лучшие предложения
                    </div>
                </div>
                <BestOffers/>

                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment' onClick={() => navigate(`/services`)}>
                        Услуги
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{anotherProducts} {NormalizeCountForm(anotherProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={anotherProductsId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Услуги отсутствуют"}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment'
                         onClick={() => navigate(`catalog/manufacturing-products`)}>
                        Продукты производства
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{manufacturingProducts} {NormalizeCountForm(manufacturingProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={manufacturingProductsId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Услуги отсутствуют"}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
            </BrowserView>

            <MobileView>
                <div className='venue-gradient-box'>
                    <GradientBox/>
                </div>

                <div className='venue-home-title'>
                    {t('workingInTeam')}
                </div>
                <div className='asiaexpo-venue-title'>
                    ASIAEXPO VENUE
                </div>
                <div className='static-home-box'>
                    <div className='static-home-block'>
                        <img src={PartnerOffers} className='static-home-box-image'/>
                        <p>Лучшие предложения партнеров из разных стран</p>
                    </div>
                    <div className='static-home-block'>
                        <img src={GlobalIcon} className='static-home-box-image'/>
                        <p>Комфортный выход на рынки Глобального Юга</p>
                    </div>
                    <div className='static-home-block'>
                        <img src={MarketEntry} className='static-home-box-image'/>
                        <p>Доступно во всех странах</p>
                    </div>
                </div>
                <div className='title-for-suppliers first-section'>
                    <span className='text-congress-online companies home'>{t('ourSuppliers')}</span>

                </div>
                <SupplierCard/>

                <div className='title-for-suppliers'>
                    <div className='text-segment companies home segment'
                         onClick={() => navigate(`/catalog/animal-husbandry`)}>
                        Животноводство
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{animalHusbandryProducts} {NormalizeCountForm(animalHusbandryProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={animalHusbandryCategoryId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Продукты животноводства отсутствуют "}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment'
                         onClick={() => navigate(`/catalog/plant-production`)}>
                        Растениеводство
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{cropProductionProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={cropProductionCategoryId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Продукты растениеводства отсутствуют "}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment' onClick={() => navigate(`/technique`)}>
                        Техника
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{techniqueProducts} {NormalizeCountForm(techniqueProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={techniqueProductsId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Продукты техники отсутствуют"}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment'>
                        Лучшие предложения
                    </div>
                </div>
                <BestOffers/>

                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment' onClick={() => navigate(`/services`)}>
                        Услуги
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{anotherProducts} {NormalizeCountForm(anotherProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={anotherProductsId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Услуги отсутствуют"}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
                <div className='title-for-suppliers '>
                    <div className='text-segment companies home segment'
                         onClick={() => navigate(`catalog/manufacturing-products`)}>
                        Продукты производства
                        <ArrowIcon className='in-congress-online-image'/>
                    </div>
                    <div
                        className='products-amount'>{manufacturingProducts} {NormalizeCountForm(manufacturingProducts, wordsArray)}</div>
                </div>
                <div className='under-category-text'>{t('browseOffers')}</div>
                <div className='products-home-container'>
                </div>
                <div className='all-home-products'>
                    <ProductsContainer
                        products={products}
                        category={manufacturingProductsId}
                        isLoading={loading}
                        loaderText={"Загружаем продукты ..."}
                        zeroText={"Услуги отсутствуют"}
                        maxItems={MAX_PRODUCTS_TO_DISPLAY}
                    />
                </div>
            </MobileView>
        </>
    );
}
