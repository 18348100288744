import { combineReducers } from 'redux';
import * as types from './actions';
import {SET_COUNTRY, SET_REGION} from "./actions";

const initialLocalState = {
    country: '',
    region: ''
}

const search = (state = false, action = 0) => {
  switch (action.type) {
    case types.SEARCH_OPEN:
      return true;
    case types.SEARCH_CLOSE:
      return false;
    default:
      return state;
  }
};

const auth = (state = false, action = 0) => {
  switch (action.type) {
    case types.AUTH_OPEN:
      return true;
    case types.AUTH_CLOSE:
      return false;
    default:
      return state;
  }
};

const region = (state = initialLocalState, action) => {
  switch (action.type) {
    case SET_COUNTRY:
      return {
        ...state,
        country: action.country
      };
    case SET_REGION:
      return {
        ...state,
        region: action.region
      }
    default:
      return state;
  }
}


export const isSearchVisible = (state) => state.search;
export const isAuthVisible = (state) => state.auth;
export const isLocalUserData = (state) => state.region;

export default combineReducers({ search, auth, region });
