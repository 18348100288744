import React, {useEffect} from 'react';
import '../Catalog/style.css'
import Products from "../Products";
import GradientBox from "../../components/GradientBox/gradientBox";
import { isMobile, isBrowser } from 'react-device-detect';
import FilterModal from "../../components/Filtration/filter";
import FilterDropdownMenu from "../../components/Filtration/dropdownMenu";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {useLocation} from "react-router";
import ProductsContainer from "../../components/ProductsContainer";
import {getProducts, getProductsFetching} from "../Products/reducer";

const Services = () => {


    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])


    const products = useSelector((state) => getProducts(state.products));
    const loading = useSelector((state) => getProductsFetching(state.products));


    const mobileView = (
        <div className="service-div">
            <h1 className={'title-text-mobile'} style={{fontSize: '20px', marginTop: '15px'}}>{t('services')}</h1>
            <br/>
            <div style={{position: 'absolute', top: 0, left: '2.4%', paddingRight: '100px'}}>
                <FilterModal isMobile={true}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <ProductsContainer
                    products={products}
                    category={Number(223)}
                    isLoading={loading}
                    loaderText="Загружаем продукты ..."
                    zeroText="походу что-то не так"
                />
            </div>
            <div>
                <GradientBox/>
            </div>
        </div>
    )

    const browserView = (
        <div className="catalog">
            <h1 className={'title-text'} id={'title'}>{t('services')}</h1>
            <FilterDropdownMenu/>
            <ProductsContainer
                products={products}
                category={Number(223)}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="походу что-то не так"
            />
            <GradientBox/>
        </div>
    )

    return (
        <>
            {isMobile ? mobileView : isBrowser ? browserView : null}
        </>
    )
}

export default Services;