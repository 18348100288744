import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Card, Grid, Button, Icon, Input, Image,
} from 'semantic-ui-react';
import { cartProductPropType } from './reducer';
import { setQuantity, removeProduct } from './actions';
import CircularImage from '../../components/CircularImage';
import config from '../../config/config';
import {ReactComponent as TrashcanIcon} from "../../icons/trashcan-icon.svg";
import CartHeartIcon from "../../icons/cart-heart-icon.png";
import {isBrowser, isMobile} from "react-device-detect";
import './styles.css';
import {Link} from "react-router-dom";

class CartProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: this.props.product.quantity,
    };

    this.increaseItemQuantity = this.increaseItemQuantity.bind(this);
    this.reduceItemQuantity = this.reduceItemQuantity.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  getProductSelections() {
    if (!this.props.product.selections) {
      return null;
    }

    const description = Object.keys(this.props.product.selections)
        .map((key) => `${key}: ${this.props.product.selections[key]}`)
        .join(', ');

    return (
        <Grid.Row>
          <Grid.Column width={16}>{description}</Grid.Column>
        </Grid.Row>
    );
  }

  /**
   * Increase product quantity
   */
  increaseItemQuantity() {
    const quantity = this.state.quantity + 1;
    this.setState({ quantity });

    const { dispatch } = this.props;
    dispatch(setQuantity(this.props.product.id, this.props.product.variationId, quantity));
  }

  /**
   * Decrease product quantity
   */
  reduceItemQuantity() {
    const { dispatch } = this.props;

    const quantity = this.state.quantity - 1;

    if (quantity === 0) {
      dispatch(removeProduct(this.props.product.id, this.props.product.variationId));
      return;
    }

    this.setState({ quantity });
    dispatch(setQuantity(this.props.product.id, this.props.product.variationId, quantity));
  }

  /**
   * Delete product from the cart
   */
  removeItem() {
    const { dispatch } = this.props;
    dispatch(removeProduct(this.props.product.id, this.props.product.variationId));
  }


  render() {
    // console.log(this.props.product)
    return (
        <Card className="cart-product">
          <Card.Content>
            <Grid doubling>
              <Grid.Row key={this.props.product.id}>
                  <Grid.Column width={3} textAlign="center" className="first-column">
                    <Link to={'/products/product-card/' + this.props.product.id}>
                      <CircularImage className="cart-product-image" src={this.props.product.image} circular={false} />
                    </Link>
                  </Grid.Column>
                <Grid.Column width={9} className="second-column break-words">
                  <div className={'product-title-text'}>{this.props.product.name}</div>
                  <span className={'second-column-text'}><b>Упаковка: </b></span>
                </Grid.Column>
                <Grid.Column
                    width={3}
                    textAlign="right"
                    className="fourth-column"
                >
                  <div style={{
                    display: 'flex',
                    justifyContent: isMobile ? 'space-between' : 'flex-end',
                    alignItems: 'center'
                  }}>
                    <Button icon onClick={this.reduceItemQuantity} className="cart-button"
                            style={isMobile ? {transform: 'scale(0.6)'} : {
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                      <Icon name="minus" size={isMobile ? "small" : "tiny"} color={"black"}/>
                    </Button>
                    <Input value={this.state.quantity} readOnly className="cart-quantity-input"
                           style={isMobile ? {width: '40px'} : {padding: '0 5px'}}/>
                    <Button icon onClick={this.increaseItemQuantity} className="cart-button"
                            style={isMobile ? {transform: 'scale(0.6)'} : {
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                      <Icon name="plus" size={isMobile ? "small" : "tiny"} color={"black"}/>
                    </Button>
                  </div>

                  <div className="icon-container">
                    <Button icon className="cart-heart">
                      <Image src={CartHeartIcon}/>
                    </Button>
                    <Button icon className="cart-delete" onClick={this.removeItem}>
                      <TrashcanIcon/>
                    </Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
              {this.getProductSelections()}
            </Grid>
          </Card.Content>
        </Card>
    );
  }

}


CartProduct.propTypes = {
  product: cartProductPropType.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return { dispatch, ...bindActionCreators({ setQuantity, removeProduct }, dispatch) };
}

export default connect(null, mapDispatchToProps)(CartProduct);
