import React, { useState, useEffect, useRef } from 'react';
import { Grid, Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './style.css';
import { ReactComponent as DropdownIcon } from "../../icons/dropdown-icon.svg";

const FilterList = ({ filters, handleFilterSelection, selectedFilters }) => {
    return (
        <Grid columns={3} className="filter-list">
            {filters.map((filter, index) => (
                <Grid.Column key={index} className="custom-checkbox">
                    <Checkbox
                        className="custom-checkbox-item"
                        label={filter}
                        checked={selectedFilters.includes(filter)}
                        onChange={() => handleFilterSelection(filter)}
                    />
                </Grid.Column>
            ))}
        </Grid>
    );
};


const FilterDropdown = ({ title, filters, isOpen, toggleDropdown, handleFilterSelection, selectedFilters }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
        } else if (contentRef.current) {
            contentRef.current.addEventListener('transitionend', handleTransitionEnd);
        }

        return () => {
            if (contentRef.current) {
                contentRef.current.removeEventListener('transitionend', handleTransitionEnd);
            }
        };
    }, [isOpen]);

    const handleTransitionEnd = () => {
        if (contentRef.current) {
            contentRef.current.removeEventListener('transitionend', handleTransitionEnd);
        }
        setIsAnimating(false);
    };

    return (
        <>
            <span className="main-text" style={{ cursor: 'pointer' }} onClick={toggleDropdown}>
                {title} <DropdownIcon className={`dropdown-icon-all-filtres ${isOpen ? 'open' : ''}`} />
            </span>
            <div
                ref={contentRef}
                className={`filter-dropdown-content ${isOpen ? 'open' : ''} ${isAnimating ? 'animating' : ''}`}
            >
                {(isOpen || isAnimating) && (
                    <FilterList
                        filters={filters}
                        handleFilterSelection={handleFilterSelection}
                        selectedFilters={selectedFilters}
                    />
                )}
            </div>
            <br/>
        </>
    );
};

export default FilterDropdown;
