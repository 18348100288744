import React, { useState } from 'react';

import { Dropdown, Input, Checkbox, Button, ButtonGroup } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { getLanguage } from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";
import { getProducts } from "../../views/Products/reducer";
import { ReactComponent as DropdownIcon } from "../../icons/dropdown-icon.svg";


const animalOptions = [
    { key: 1, text: "для коров", value: 1 },
    { key: 2, text: "для коз", value: 2 },
    { key: 3, text: "для кроликов", value: 3 },
    { key: 4, text: "для птицы", value: 4 },
    { key: 5, text: "для лошадей", value: 5 },
    { key: 6, text: "для овец", value: 6 },
    { key: 7, text: "для овец", value: 7 },
    { key: 8, text: "для овец", value: 8 },
];

const categoryOptions = [
    { key: 1, text: "Категория 1", value: 1 },
    { key: 2, text: "Категория 2", value: 2 },
    { key: 3, text: "Категория 3", value: 3 },
];

const popularityOptions = [
    { key: 1, text: 'по убыванию', value: 1 },
    { key: 2, text: 'по возрастанию', value: 2 }
];

const SelectedFilters = ({ selectedOptions, handleRemoveFilter }) => {
    return (
        <div className="selected-filters">
            {Object.keys(selectedOptions).map(filterKey => (
                selectedOptions[filterKey].map((option, index) => (
                    <div key={`${filterKey}-${index}`} className="selected-filter-item">
                        <span>{option.text}</span>
                        <button
                            style={{ color: 'white' }}
                            onClick={() => handleRemoveFilter(filterKey, option.value)}
                        >
                            &times;
                        </button>
                    </div>
                ))
            ))}
        </div>
    );
};


const CheckboxDropdown = ({
                              options,
                              selectedOptions,
                              setSelectedOptions,
                              searchTerm,
                              setSearchTerm,
                              type,  // Добавили type как аргумент для правильного обновления state
                              showMore,
                              setShowMore,
                              onDoneClick,
                              onResetClick,
                          }) => {
    const [inputVisible, setInputVisible] = useState(false);

    const toggleOption = (option) => {
        const updatedOptions = Array.isArray(selectedOptions) ? selectedOptions : [];

        // Проверяем, выбран ли уже этот элемент
        const isSelected = updatedOptions.some((selected) => selected.value === option.value);

        if (isSelected) {
            // Удаляем элемент из выбранных
            const newOptions = updatedOptions.filter((selected) => selected.value !== option.value);
            setSelectedOptions(newOptions); // Обновляем состояние напрямую
        } else {
            // Добавляем новый элемент к выбранным
            const newOptions = [...updatedOptions, option];
            setSelectedOptions(newOptions); // Обновляем состояние напрямую
        }
    };

    const filterOptions = (options) =>
        options.filter((option) => option.text.toLowerCase().includes(searchTerm.toLowerCase()));

    const visibleOptions = filterOptions(options);
    const displayedOptions = showMore ? visibleOptions : visibleOptions.slice(0, 7);

    return (
        <div className={"filter-checkbox-dropdown"}>
            <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
                {inputVisible && (
                    <Dropdown.Item key="search" className="filter-search-input">
                        <Input
                            icon="search"
                            placeholder="Поиск..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Dropdown.Item>
                )}
                {displayedOptions.map((option) => (
                    <Dropdown.Item key={option.key} onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            label={option.text}
                            checked={Array.isArray(selectedOptions) && selectedOptions.some((selected) => selected.value === option.value)}
                            onChange={() => toggleOption(option)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Dropdown.Item>
                ))}
                {visibleOptions.length > 7 && (
                    !showMore ? (
                        <Dropdown.Item key="show-more" onClick={(e) => {
                            e.stopPropagation();
                            setShowMore(true);
                            setInputVisible(true);
                        }}>
                            <Button className="show-more-button">Показать ещё</Button>
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item key="show-less" onClick={(e) => {
                            e.stopPropagation();
                            setShowMore(false);
                            setInputVisible(false);
                        }}>
                            <Button className="show-more-button">Показать меньше</Button>
                        </Dropdown.Item>
                    )
                )}
                <ButtonGroup className={'filter-buttons-done'}>
                    <Button className={'filter-button-done'} onClick={onResetClick}>Сбросить</Button>
                    <Button className={'filter-button-done'} onClick={() => onDoneClick(type, selectedOptions)}>Готово</Button>
                </ButtonGroup>
            </Dropdown.Menu>
        </div>
    );
};

const FilterDropdownMenu = (props) => {
    const { isOffers } = props;
    const language = useSelector(getLanguage);
    const products = useSelector((state) => getProducts(state.products));
    const allSellers = products.flatMap(product => product.seller_company || []);
    const uniqueSellers = Array.from(new Map(allSellers.filter(seller => seller && seller.id).map(seller => [seller.id, seller])).values());

    const sellerOptions = uniqueSellers.map(seller => ({
        key: seller.id,
        text: seller.name,
        value: seller.id,
    }));

    const [showMore, setShowMore] = useState({
        seller: false,
        animal: false,
        category: false,
        popularity: false
    });

    const [activeDropdown, setActiveDropdown] = useState(null); // Track the active dropdown

    const t = (key) => {
        const languageString = language[0] + language[1];
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    const handleDropdownClick = (dropdownName) => {
        setActiveDropdown((prevDropdown) => (prevDropdown === dropdownName ? null : dropdownName));
    };

    const closeDropdown = () => {
        setActiveDropdown(null);
    };

    const [searchTermAnimal, setSearchTermAnimal] = useState('');
    const [searchTermSeller, setSearchTermSeller] = useState('');
    const [searchTermCategory, setSearchTermCategory] = useState('');
    const [searchTermPopularity, setSearchTermPopularity] = useState('');

    const [selectedOptions, setSelectedOptions] = useState({
        animal: [],
        seller: [],
        category: [],
        popularity: []
    });


    const handleResetFilters = (type) => {
        setSelectedOptions(prevSelectedOptions => ({
            ...prevSelectedOptions,
            [type]: []
        }));
        closeDropdown();
    };

    const handleDoneClick = (type, selectedOptions) => {
        setSelectedOptions(prevSelectedOptions => ({
            ...prevSelectedOptions,
            [type]: selectedOptions
        }));
        closeDropdown();
    };

    const handleRemoveFilter = (type, value) => {
        setSelectedOptions(prevSelectedOptions => ({
            ...prevSelectedOptions,
            [type]: prevSelectedOptions[type].filter(option => option.value !== value)
        }));
    };

    console.log(selectedOptions)
    return (
        <div className="filtration">
            <Dropdown
                className="filtration-button"
                text={"Метки"}
                icon={<DropdownIcon className="dropdown-icon" />}
                scrolling
                closeOnBlur={false}
                open={activeDropdown === "animal"}
                onClick={() => handleDropdownClick("animal")}
            >
                <CheckboxDropdown
                    options={animalOptions}
                    selectedOptions={selectedOptions.animal}
                    setSelectedOptions={(newOptions) => {
                        console.log("New options for animal:", newOptions); // Отладка
                        setSelectedOptions(prev => ({ ...prev, animal: newOptions }));
                    }}
                    searchTerm={searchTermAnimal}
                    setSearchTerm={setSearchTermAnimal}
                    type="animal"
                    showMore={showMore.animal}
                    setShowMore={(val) => setShowMore(prev => ({ ...prev, animal: val }))}
                    onCloseDropdown={closeDropdown}
                    onDoneClick={handleDoneClick}
                    onResetClick={() => handleResetFilters('animal')}
                />
            </Dropdown>
            {!isOffers && (
                <Dropdown
                    className="filtration-button"
                    text={t("seller")}
                    icon={<DropdownIcon className="dropdown-icon" />}
                    scrolling
                    closeOnBlur={false}
                    open={activeDropdown === "seller"}
                    onClick={() => handleDropdownClick("seller")}
                >
                    <CheckboxDropdown
                        options={sellerOptions}
                        selectedOptions={selectedOptions.seller}
                        setSelectedOptions={(newOptions) => setSelectedOptions(prev => ({ ...prev, seller: newOptions }))}
                        searchTerm={searchTermSeller}
                        setSearchTerm={setSearchTermSeller}
                        type="seller"
                        showMore={showMore.seller}
                        setShowMore={(val) => setShowMore(prev => ({ ...prev, seller: val }))}
                        onCloseDropdown={closeDropdown}
                        onDoneClick={handleDoneClick}
                        onResetClick={() => handleResetFilters('seller')}
                    />
                </Dropdown>
            )}
            <Dropdown
                className="filtration-button"
                text={t("category")}
                icon={<DropdownIcon className="dropdown-icon" />}
                scrolling
                closeOnBlur={false}
                open={activeDropdown === "category"}
                onClick={() => handleDropdownClick("category")}
            >
                <CheckboxDropdown
                    options={categoryOptions}
                    selectedOptions={selectedOptions.category}
                    setSelectedOptions={(newOptions) => setSelectedOptions(prev => ({ ...prev, category: newOptions }))}
                    searchTerm={searchTermCategory}
                    setSearchTerm={setSearchTermCategory}
                    type="category"
                    showMore={showMore.category}
                    setShowMore={(val) => setShowMore(prev => ({ ...prev, category: val }))}
                    onCloseDropdown={closeDropdown}
                    onDoneClick={handleDoneClick}
                    onResetClick={() => handleResetFilters('category')}
                />
            </Dropdown>
            {!isOffers && (
                <Dropdown
                    className="filtration-button"
                    text={"Порядок отображения"}
                    icon={<DropdownIcon className="dropdown-icon" />}
                    scrolling
                    closeOnBlur={false}
                    open={activeDropdown === "popularity"}
                    onClick={() => handleDropdownClick("popularity")}
                >
                    <CheckboxDropdown
                        options={popularityOptions}
                        selectedOptions={selectedOptions.popularity}
                        setSelectedOptions={(newOptions) => setSelectedOptions(prev => ({ ...prev, popularity: newOptions }))}
                        searchTerm={searchTermPopularity}
                        setSearchTerm={setSearchTermPopularity}
                        type="popularity"
                        showMore={showMore.popularity}
                        setShowMore={(val) => setShowMore(prev => ({ ...prev, popularity: val }))}
                        onCloseDropdown={closeDropdown}
                        onDoneClick={handleDoneClick}
                        onResetClick={() => handleResetFilters('popularity')}
                    />
                </Dropdown>
            )}
            <SelectedFilters
                selectedOptions={selectedOptions}
                handleRemoveFilter={handleRemoveFilter}
            />
        </div>
    );
};

export default FilterDropdownMenu;

