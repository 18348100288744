import 'whatwg-fetch';
import config from '../../config/config';
import {requestProducts} from "../../views/Products/actions";

export const REQUEST_REVIEWS = 'REQUEST_REVIEWS';
export const RECEIVE_REVIEWS = 'RECEIVE_REVIEWS';
export const REVIEWS_PAGE_COUNT = 4;

export const requestReviews = () => ({
  type: REQUEST_REVIEWS,
});

export const receiveReviews = (reviews, productId, page) => ({
  type: RECEIVE_REVIEWS,
  reviews,
  productId,
  page
});

export const fetchReviews = (productId, params) => (dispatch) => {

  dispatch(requestReviews());
  let url;
  if (params) {
    url = config.API_REVIEWS_URL + productId
        + '?'
        + Object.keys(params)
            .map((k) => k + '=' + encodeURIComponent(params[k]))
            .join('&');
  }

  return fetch(url)
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveReviews(json, productId, params.page ?? 1));
    })
    .catch(() => {
      dispatch(receiveReviews([], productId));
    });
};
