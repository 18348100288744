import React, { useState } from 'react';
import "./style.css";
import filtrationIcon from "../../icons/filtration-icon.png";
import { Modal, ModalContent, Button } from "semantic-ui-react";
import FilterDropdown from "./menu.js";
import {ReactComponent as CloseIcon} from "../../icons/filter-close-button.svg";
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";
import {getProducts} from "../../views/Products/reducer";



function filterReducer(state, action) {
    switch (action.type) {
        case 'close':
            return { open: false };
        case 'open':
            return { open: true, size: action.size };
        default:
            throw new Error('Unsupported action...');
    }
}

const FilterModal = (props) => {

    const products = useSelector((state) => getProducts(state.products));
    const allSellers = products.flatMap(product => product.seller_company);
    const uniqueSellers = Array.from(new Map(allSellers.map(seller => [seller.id, seller])).values());

    const sellerOptions = uniqueSellers.map(seller => seller.name);

    const filters2 = ['для коров', 'для кроликов', 'для птицы', 'для коз', 'для лошадей', 'для овец'];
    const filters3 = sellerOptions;
    const filters4 = ['Категория 1', 'Категория 2', 'Категория 3'];
    const filters5 = ['По убыванию', 'По возрастанию'];

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const [state, dispatch] = React.useReducer(filterReducer, {
        open: false,
        size: undefined,
    });
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedFilters2, setSelectedFilters2] = useState([]);
    const [selectedFilters3, setSelectedFilters3] = useState([]);
    const [selectedFilters4, setSelectedFilters4] = useState([]);
    const [selectedFilters5, setSelectedFilters5] = useState([]);

    const {isMobile, isOffers} = props;


    const { open, size } = state;

    const handleFilterSelection = (selectedFilters, setSelectedFilters, isSingleSelect = false) => (filter) => {
        if (isSingleSelect) {
            if (selectedFilters.includes(filter)) {
                setSelectedFilters([]);  // Очистить выбор, если фильтр уже выбран
            } else {
                setSelectedFilters([filter]);  // Выбрать новый фильтр
            }
        } else {
            if (selectedFilters.includes(filter)) {
                setSelectedFilters(selectedFilters.filter(item => item !== filter));
            } else {
                setSelectedFilters([...selectedFilters, filter]);
            }
        }
    };


    const toggleDropdown = (dropdownName) => {
        if (openDropdown === dropdownName) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdownName);
        }
    };

    const mobileStyle = isMobile ? {boxShadow: 'none', position:'absolute',left:'-38px',top:'45px',background:'none', fontSize:'10px',fontWeight:'bold',textAlign:'center'} : { cursor: 'pointer' }

    return (
        <>
            <span id={'all-filter-button'} style={mobileStyle} onClick={() => dispatch({ type: open ? 'close' : 'open', size: 'mini' })}>
                {isMobile ? (
                    <>
                        <img id={'img-filter'} src={filtrationIcon} alt={''} />
                        {t('allFilters')}
                    </>
                ) : (
                    <>
                        {t('allFilters')}
                        <img id={'img-filter'} src={filtrationIcon} alt={''}/>
                    </>
                )}
            </span>
            <Modal
                size={size}
                open={open}
                onClose={() => dispatch({ type: 'close' })}
                className="all-filter-modal"
            >
                <Button
                    icon
                    style={{
                        float: 'right',
                        margin: '10px',
                        backgroundColor: 'white',
                    }}
                    onClick={() => dispatch({ type: 'close' })}
                >
                    <CloseIcon/>
                </Button>
                <ModalContent>
                    <p id={"filter-title-text"}>Фильтры</p>
                    <FilterDropdown
                        title={t('typeOfAnimal')}
                        filters={filters2}
                        isOpen={openDropdown === 'animalType'}
                        toggleDropdown={() => toggleDropdown('animalType')}
                        handleFilterSelection={handleFilterSelection(selectedFilters2, setSelectedFilters2)}
                        selectedFilters={selectedFilters2}
                    />
                    {!isOffers && <FilterDropdown
                        title={t('seller')}
                        filters={filters3}
                        isOpen={openDropdown === 'seller'}
                        toggleDropdown={() => toggleDropdown('seller')}
                        handleFilterSelection={handleFilterSelection(selectedFilters3, setSelectedFilters3)}
                        selectedFilters={selectedFilters3}
                    />}
                    <FilterDropdown
                        title={t('category')}
                        filters={filters4}
                        isOpen={openDropdown === 'category'}
                        toggleDropdown={() => toggleDropdown('category')}
                        handleFilterSelection={handleFilterSelection(selectedFilters4, setSelectedFilters4)}
                        selectedFilters={selectedFilters4}
                    />
                    {!isOffers && <FilterDropdown
                        title={"Порядок отображения"}
                        filters={filters5}
                        isOpen={openDropdown === 'popularity'}
                        toggleDropdown={() => toggleDropdown('popularity')}
                        handleFilterSelection={handleFilterSelection(selectedFilters5, setSelectedFilters5, true)}  // single select
                        selectedFilters={selectedFilters5}
                    />}
                    <Button
                        fluid
                        id="all-filter-modal-button"
                        onClick={() => dispatch({ type: 'close' })}
                    >
                        Применить
                    </Button>
                </ModalContent>
            </Modal>
        </>
    );
};

export default FilterModal;

