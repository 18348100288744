import PropTypes from 'prop-types';
import {
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
  EmailIcon,
  WhatsappIcon,
  TelegramIcon, VKShareButton, OKShareButton, VKIcon, OKIcon
} from 'react-share';
import { Header, Card, Grid } from 'semantic-ui-react';

export default function SocialBox(props) {

  const { permalink, title, body, image } = props;

    return (
      <Card centered>
        <Card.Content>
          <Card.Header as={Header} size="tiny">
            Поделиться
          </Card.Header>
          <Grid doubling centered>
            <Grid.Row>
              <Grid.Column width={3}>
                <EmailShareButton className="no-outline" url={permalink} subject={title} body={body} >
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
              </Grid.Column>
              <Grid.Column width={3}>
                <WhatsappShareButton className="no-outline" url={permalink} title={title + " " + body} >
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
              </Grid.Column>
              <Grid.Column width={3}>
                <TelegramShareButton className="no-outline" url={permalink} title={title + " " + body} >
                  <TelegramIcon size={32} round={true} />
                </TelegramShareButton>
              </Grid.Column>
              <Grid.Column width={3}>
                <VKShareButton className="no-outline" url={permalink} title={title + " " + body} image={image} >
                  <VKIcon size={32} round={true} />
                </VKShareButton>
              </Grid.Column>
              <Grid.Column width={3}>
                <OKShareButton className="no-outline" url={permalink} title={title} description={body} image={image} >
                  <OKIcon size={32} round={true} />
                </OKShareButton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    );
}

SocialBox.propTypes = {
  permalink: PropTypes.string.isRequired,
};
