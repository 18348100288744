import { combineReducers } from 'redux';
import {
    REQUEST_COMPANIES,
    RECEIVE_COMPANIES,
    COMPANIES_PAGE_COUNT,
    SET_COUNTRY_FILTER,
    CLEAR_COUNTRY_FILTER, CLEAR_SEGMENT_FILTER, SET_SEGMENT_FILTER, RECEIVE_SEGMENTS, REQUEST_SEGMENTS
} from './actions';
import {mergeObjectArrays} from "../../configureStore";

const initialState = {
    country: [],
    segment: [],
};

const items = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_COMPANIES:
            if (action.page === 1)
                return action.companies;
            else
                return mergeObjectArrays(action.companies, state);
        default:
            return state;
    }
};

const hasMore = (state = false, action = 0) => {
    switch (action.type) {
        case REQUEST_COMPANIES:
            return true;
        case RECEIVE_COMPANIES:
            return action.companies.length >= COMPANIES_PAGE_COUNT;
        default:
            return state;
    }
};

const isFetching = (state = false, action) => {
    switch (action.type) {
        case REQUEST_COMPANIES:
        case REQUEST_SEGMENTS:
            return true;
        case RECEIVE_COMPANIES:
        case RECEIVE_SEGMENTS:
            return false;
        default:
            return state;
    }
};

const filter = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTRY_FILTER:
            return {
                ...state,
                country: action.country,
            };
        case CLEAR_COUNTRY_FILTER:
            return {
                ...state,
                country: [],
            };
        case CLEAR_SEGMENT_FILTER:
            return {
                ...state,
                segment: [],
            };
        case SET_SEGMENT_FILTER:
            return {
                ...state,
                segment: action.segment,
            };
        default:
            return state;
    }
};

const segments = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_SEGMENTS:
            return action.segments;
        default:
            return state;
    }
};

export const getCompanies = (state) => state.items;
export const getCompaniesFetching = (state) => state.isFetching;
export const getCompaniesHasMore = (state) => state.hasMore;
export const getCompaniesFilter = (state) => state.filter;
export const getSegments = (state) => state.segments;

export default combineReducers({
    items,
    isFetching,
    hasMore,
    filter,
    segments
});
