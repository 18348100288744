import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import config from '../../config/config';
import {Image, Segment} from 'semantic-ui-react';
import { getCart, getTotalPrice, hasOnlyVirtuals } from './reducer';
import CartProduct from './CartProduct';
import CardSummary from '../../components/CardSummary/CardSummary';
import { isMobile, isBrowser } from "react-device-detect";
import './styles.css';
import {ReactComponent as DividerIcon} from "../../icons/cart-divider.svg";
import {Link} from "react-router-dom";

export default function Cart(props) {
    const { userLocation, userData, mixpanel } = props;

    const cart = useSelector((state) => getCart(state.cart));

    const groupedCart = _.groupBy(cart, 'seller_company_id');

    useEffect(() => {
        if (mixpanel)
            mixpanel.track('Cart View', { ...userLocation, ...userData, cart });
        window.scrollTo(0, 0);
    }, []);

    // Функция для вычисления общей стоимости товаров в группе
    const calculateGroupTotal = (group) => {
        return _.sumBy(group, (item) => Number(item.quantity) * Number(item.price));
    };

    // console.log(groupedCart);
    const renderGroup = (group, sellerInfo) => (
        <div key={sellerInfo.id}>
            <div className="seller-title">
                <span style={{color:'#818181', fontSize:'13px'}}>Поставщик</span>
                <Link to={'/congress-online/company/' + sellerInfo.id} style={{color:'#663043', display:'flex',flexDirection:'row', alignItems:'center'}} >
                    <Image src={sellerInfo.logo} style={{width:'67px', height:'67px', marginRight:'10px'}} alt={sellerInfo.name}/> {sellerInfo.name}</Link>
            </div>
            <div className={'cart-products-group'}>
                <div className="cart-products">
                    {group.map((product) => (
                        <CartProduct
                            key={_.isNil(product.variationId) ? product.id : product.variationId}
                            product={product}
                            show_buttons={true}
                            {...props}
                        />
                    ))}
                </div>
                <div className="cart-summary-container">
                    <CardSummary
                        total_products={calculateGroupTotal(group)}
                        cart_length={group.length}
                        only_virtuals={hasOnlyVirtuals({items: group})}
                        config={config}
                    />
                </div>
            </div>
            <DividerIcon style={isMobile ? {width:'100%'} : {margin:'30px'}}/>
        </div>
    );

    const mobileView = (
        <div>
            <div className="title-container">
                <div className={'cart-title-text'} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '5px',
                    height: '50px',
                    fontSize: "20px"
                }}>Состав предложения
                </div>
            </div>
            <div className="cart-container">
                {Object.keys(groupedCart).map((sellerId) => {
                    const group = groupedCart[sellerId];
                    const sellerInfo = {
                        id: group[0].seller_company_id,
                        name: group[0].seller_company,
                        logo: group[0].seller_company_logo,
                    };
                    return renderGroup(group, sellerInfo);
                })}
            </div>
        </div>
    );

    const browserView = (
        <div>
            <div className="title-container">
                <div className={'title-text'} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                    height: '50px'
                }}>Состав предложения
                </div>
            </div>
            <div className="cart-container">
                {Object.keys(groupedCart).map((sellerId) => {
                    const group = groupedCart[sellerId];
                    const sellerInfo = {
                        id: group[0].seller_company_id,
                        name: group[0].seller_company,
                        logo: group[0].seller_company_logo,
                    };
                    return renderGroup(group, sellerInfo);
                })}
            </div>
        </div>
    );

    return _.isEmpty(cart) ? (
        <>
            <div className="title-container">
                <div className={'cart-title-text'} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                    height: '50px'
                }}>Состав предложения
                </div>
            </div>
            <Segment textAlign="center">Пусто</Segment>
        </>
    ) : (
        <div>{isMobile ? mobileView : isBrowser ? browserView : null}</div>
    );
}
