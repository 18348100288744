import { Link } from 'react-router-dom';
import React from 'react';
import { Card, Button, Segment, Image } from 'semantic-ui-react';

import './styles.css';

export default function CategoryCard(props) {
    return (
      <Card centered>
        <Card.Content className="category-card-content">
          <Link className="category-card-image-href" to={props.src !== '' ? '/categories/' + props.id : '/products/' + props.id}>
            <Image src={props.src} className="category-card-image"/>
          </Link>
          <Segment basic className="category-meta-container">
            <Card.Content size="tiny" className="category-content-container">
                {/*<Card.Header as="h3" textAlign="center">{props.name}</Card.Header>*/}
                <Card.Description>{props.desc}</Card.Description>
            </Card.Content>
            <Link to={'/products/' + props.id} className="category-button-products">
              <Button color="blue" compact>
                Перейти к товарам &gt;
              </Button>
            </Link>
          </Segment>
        </Card.Content>
      </Card>
    );
}
