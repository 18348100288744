export const SET_FORM_CHANGED = 'SET_FORM_CHANGED';
export const RESET_FORM_CHANGED = 'RESET_FORM_CHANGED';
export const SEARCH_OPEN = 'SEARCH_OPEN';
export const SEARCH_CLOSE = 'SEARCH_CLOSE';
export const AUTH_OPEN = 'AUTH_OPEN';
export const AUTH_CLOSE = 'AUTH_CLOSE';
export const CONSUMER_KEY='ck_cc0a022a5d3947aa6a7999949e630e7ce84a3943'
export const CONSUMER_SECRET='cs_398d29cc68089b9aba16d452224b5880c6cb8a8a'
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_REGION = 'SET_REGION';

export const openSearch = () => ({
  type: SEARCH_OPEN,
});

export const closeSearch = () => ({
  type: SEARCH_CLOSE,
});

export const openAuth = () => ({
  type: AUTH_OPEN,
});

export const closeAuth = () => ({
  type: AUTH_CLOSE,
});

export const setFormChanged = () => ({
  type: SET_FORM_CHANGED,
});

export const resetFormChanged = () => ({
  type: RESET_FORM_CHANGED,
});

export const setCountry = (country) => ({
    type: SET_COUNTRY,
    country,
});

export const setRegion = (region) => ({
    type: SET_REGION,
    region,
});


export const sendEmail = (data) => {
  let url =
      'https://stage.e-feed.ru/wp-json/app/v1/create-ticket/?' +
      Object.keys(data)
          .map((k) => k + '=' + encodeURIComponent(data[k]))
          .join('&');

  return fetch(url, { method: 'post' })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 200) {
          console.log('send mail', json)
        } else {
          console.warn(' error while put ticket', url, data, json);
        }
      })
      .catch((ex) => {
        console.error('Ошибка запроса: ' + String(ex));
      });
};