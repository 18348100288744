import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {Button, Card, Header} from 'semantic-ui-react';
import {fetchReviews, REVIEWS_PAGE_COUNT} from './actions';
import {getReviewsFetching, getReviews, getReviewsHasMore} from './reducer';
import {useParams} from "react-router";
import Rating from "../Rating";
import { ReactComponent as UserIcon } from "../../icons/user-icon.svg";
import Review from "./Review";
import {BrowserView, MobileView} from "react-device-detect";

export default function Reviews(props) {

  const {dispatch} = props;
  const { productId } = useParams()

  const loading = useSelector((state) => getReviewsFetching(state.reviews));
  const reviews = useSelector((state) => getReviews(state.reviews, productId));
  const hasMore = useSelector((state) => getReviewsHasMore(state.reviews));

  const [viewAll, setViewAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    readReviews(1);
  }, [productId]);

  function readReviews(page) {
    setViewAll(!viewAll)
    const params = {
      page,
      per_page: REVIEWS_PAGE_COUNT,
      order: 'desc',
      orderby: 'date',
    }
    if (productId)
      dispatch(fetchReviews(productId, params));
  }


  const handleViewMore = () => {
    if (hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
      readReviews(currentPage + 1);
    }
    setViewAll(true);
  };

  const handleHideReviews = () => {
    setViewAll(false);
    setCurrentPage(1);
    readReviews(1);
  };


    return  (
        <>
          <BrowserView>
            <div className={viewAll ? 'reviews-all-list' : 'reviews-list'}>
              {reviews.map(
                  review =>
                      <Review
                          key={review.id}
                          review={review}
                          {...props}
                      />
              )}
            </div>


            <div className='bottom-btn-reviews'>
              { reviews.length >= 4 ?

                  (viewAll || hasMore) && (
                      <Button className='button-view-reviews'
                              onClick={hasMore ? handleViewMore : handleHideReviews}>
                        {hasMore ? 'Смотреть еще' : 'Скрыть отзывы'}
                      </Button>
                  ) : ''

              }
            </div>


          </BrowserView>

          <MobileView>
            <div className={viewAll ? 'reviews-all-list-mobile' : 'reviews-list-mobile'}>
              {reviews.map(
                  review =>
                      <div className={viewAll ? 'review-box-all-mobile' : 'review-box'}>
                        <Review
                            key={review.id}
                            review={review}
                            {...props}
                        />
                      </div>
              )}
            </div>

            <div className='bottom-btn-reviews-mobile'>
              { reviews.length >= 1 ?

                  (viewAll || hasMore) && (
                      <Button className='button-view-reviews'
                              onClick={hasMore ? handleViewMore : handleHideReviews}>
                        {hasMore ? 'Смотреть еще' : 'Скрыть отзывы'}
                      </Button>
                  ) : ''

              }
            </div>
          </MobileView>
        </>


    );
}


