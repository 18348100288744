import React, {useEffect, useState} from 'react';
import {
    Modal, Button, Header, Form, Message, Icon, Label, Select, FormButton, ImageGroup, Image, Input, Divider,
} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {ReactComponent as CloseIcon} from "../../icons/close-icon.svg";
import {isAuthVisible, isSearchVisible} from '../NavTopBar/reducer';
import {closeAuth, closeSearch, openAuth} from '../NavTopBar/actions';
import {fetchToken, startAuthByPhone} from './actions';
import './style.css'
import './UserLogin.css';
import {getAuthMode, getUserFetching, getUserLoginError} from './reducer';
import {closeMenu} from "../NavBottomBar/actions";
import PhoneCountryMask from "../AddressCard/PhoneCountryMask";
import {ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY, fetchCounties} from "../AddressCard/actions";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { ReactComponent as AsiaExpoIcon } from '../../icons/asiaexpo-venue-icon.svg';
import { ReactComponent as AsiaExpoIcon2 } from '../../icons/asiaexpo2024-icon.svg';
import {isCountriesFetching} from "../../components/AddressCard/reducer";
import {getCountries} from "../../components/AddressCard/reducer";
import _ from "lodash";
import {toastr} from "react-redux-toastr";


export const cleanMessage = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

export default function UserLogin (props) {

    const {userData, menuVisible, searchVisible, dispatch, mixpanel, sidebarVisible} = props;
    const showModal = useSelector((state) => isAuthVisible(state.navtopbar));
    const isFetching = useSelector((state) => getUserFetching(state.userLogin));
    const messageLoginError= useSelector((state) => getUserLoginError(state.userLogin));

    const defaultSettings = {
        phone: '',
        email: userData.login ?? '',
        password: '',
        emailError: false,
        passwordError: false,
        showPassword: false,
    };

    const navigate = useNavigate()
    const [state, setState] = useState(defaultSettings);
    const [content, setContent] = useState(null);
    const [open, setOpen] = useState(false);
    const isFetchingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));

    const authVisible = useSelector((state) => isAuthVisible(state.navtopbar));

    let listPhoneCountries = [];

    listPhoneCountries =
        countries.countries ?
            Object.values(countries.countries).map((element, index) => (
                {
                    key:  index,
                    text: element.country_ru,
                    flag: _.lowerCase(element.iso),
                    value: element.iso,
                    className: "dropdown-item",
                    phone_mask: element.phone_mask,
                }
            )) : [] ;

    useEffect(() => {
        if (true) {
            dispatch(fetchCounties());
        }
    }, []);

    useEffect(() => {
      if (menuVisible)
        dispatch(closeMenu());

        if (searchVisible)
            dispatch(closeSearch());

      if (mixpanel)
          mixpanel.track('Login Dialog Open', { ...userData });

      setState(defaultSettings);
      setContent(null);

    }, [showModal === true]);

    function validateForm() {
        setState({ ...state, emailError: !state.email.length, passwordError: !state.password.length});
        return (state.email.length && state.password.length);
    }

    function submitForm() {
        if (validateForm())
            dispatch(fetchToken(state.email, state.password));
            setContent(null)
    }


    function handleSetShowPassword(show) {
        setState({...state, showPassword: show});
    }

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberContainsUnderscore, setPhoneNumberContainsUnderscore] = useState(true);

    const handleChangeField = (fieldName, value) => {
        const code =   Math.floor(1000 + Math.random() * 9000);
        console.log(code)
        if (value.includes('_')) {
            setPhoneNumberContainsUnderscore(true);
        } else {
            dispatch(startAuthByPhone(value, code));
            setPhoneNumberContainsUnderscore(false);
        }
        // генерируем код случайный из 4 цифр
        // ...

        // вызываем функцию отправки этого кода пользователю (ее надо придумать тоже)
        // ...
        // console.log(код)

        // запоминаем в глобальных стейтах телефон и отправленный код
        // ...
        // добавитиь проверку на ввод полного номера телефона
        setPhoneNumber(value);
        console.log('phone -', phoneNumber);
    }

    const PassRecoveryModal = () => {
        setState({...state, password: ''});
        console.log(state.password);

        return (
            <div className='pass-rec-modal'>
                <Form>
                <Form.Input
                    fluid
                    placeholder="Пароль"
                    onChange={(e) => setState({...state, password: e.target.value})}
                    error={state.passwordError}
                    type={state.showPassword ? "text" : "password"}
                    name="password"
                    autoComplete="on"
                    // value={state.password}
                    iconPosition="left"
                    labelPosition="right"
                    id="input-numbers">
                    <Icon name="key"/>
                    <input/>
                    <Label
                        icon={state.showPassword ? "eye" : "eye slash"}
                        onClick={() => {handleSetShowPassword(!state.showPassword); console.log('click') }}
                        className="user-login-toggle-show-password"
                        id='eye-icon-id'
                    />
                    </Form.Input>
                </Form>
                {/*<Input*/}
                {/*    fluid*/}
                {/*    placeholder="Пароль"*/}
                {/*    error={state.passwordError}*/}
                {/*    >*/}

                {/*</Input>*/}
            </div>
        )
    }

    const ConfirmCode = () => {
        const [seconds, setSeconds] = useState(30);
        const [textColor, setTextColor] = useState("#A7AFB7");
        const [inputs, setInputs] = useState(['', '', '', '']);
        const navigate = useNavigate();

        const location = useLocation();
        const phoneNumber = location.state ? location.state.phoneNumber : "";

        const authMode = useSelector((state) => getAuthMode(state.userLogin));

        function handleKeyPress(e) {
            if (['0','1','2','3','4','5','6','7','8','9','Delete'].includes(e.key)) {
                if (e.key !== 'Delete' && isNaN(e.key)) {
                    e.preventDefault();
                } else {
                    console.log('key press handle', e);
                    // обработчик по заполнению inputs
                    // при Delete стираешь последнюю цифру, иначе добавляешь новую
                }
            } else {
                e.preventDefault();
            }
        }

        useEffect(() => {
            console.log('open confirm content');
            // subscribe event
            document.addEventListener("keypress", handleKeyPress);
            return () => {
                // unsubscribe event
                console.log('close confirm content');
                document.removeEventListener("keypress", handleKeyPress);
            };
        }, [] );

        useEffect(() => {
            if (seconds > 0) {
                const intervalId = setInterval(() => {
                    setSeconds(prevSeconds => prevSeconds - 1);
                }, 1000);
                return () => clearInterval(intervalId);
            }
        }, [seconds]);

        const handleClick = () => {
            setSeconds(30);
            setTextColor('#A7AFB7');
            const code =   Math.floor(1000 + Math.random() * 9000);

            console.log('Новый код -', authMode.authCode)
        }

        const [error, setError] = useState(false)
        const handleInput = (index, value) => {
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);

            const enteredCode = newInputs.join("");
            const correctCode = authMode.authCode
            if (enteredCode.length === 4) {
                if (Number(enteredCode) === correctCode) {
                    submitForm();
                } else {
                    setError(true)
                    console.log('Неверный код -', enteredCode);
                    console.log(correctCode);
                }
            }
        }
        function testJump(x){
            var ml = ~~x.getAttribute('maxlength');
            if(ml && x.value.length >= ml){
                do{
                    x = x.nextSibling;
                }
                while(x && !(/text/.test(x.type)));
                if(x && /text/.test(x.type)){
                    x.focus();
                }
            }
        }
        return (
            <div className='confirm-code-main-block'>
                <AsiaExpoIcon width='300px' height='110px' />

                <div className='container-numbers'>
                    <span className='text-for-label'>Введите четырехзначный код <br/> отправленный на номер</span>
                    <span className='number-phone' id='changePhone'>{authMode.authPhone}</span>
                </div>
                <button id='btnCode' onClick={handleClick} disabled={seconds > 0}>
                    <span className='text-again'
                          style={{color: seconds === 0 ? 'black' : textColor}}>{seconds === 0 ? 'Отправить повторно' : `Отправить повторно (${seconds} сек)`}</span>
                </button>
                {(error) && <div style={{color: 'red', fontFamily: 'Muller-Bold'}}>Неверный код</div>}
                <div className='container-inputs'>
                    {
                        inputs.map((value, index) => (
                            <input
                                key={index}
                                id='input-number13'
                                maxLength='1'
                                type="text"
                                onInput={e => testJump(e.target)}
                                value={value}
                                onChange={(e) => handleInput(index, e.target.value)}
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    const Registration = () => {
        const [email, setEmail] = useState("");
        const [surname, setSurname] = useState("");
        const [emailDirty, setEmailDirty] = useState(false);
        const [surnameDirty, setSurnameDirty] = useState(false);
        const [emailError, setEmailError] = useState("Поле ввода не может быть пустым");
        const [surnameError, setSurnameError] = useState("Поле ввода не может быть пустым");

        const [name, setName] = useState("");
        const [nameDirty, setNameDirty] = useState(false);
        const [nameError, setNameError] = useState("Поле ввода не может быть пустым");

        const [company, setCompany] = useState("");
        const [companyDirty, setCompanyDirty] = useState(false);

        const [phone, setPhone] = useState("");
        const [phoneDirty, setPhoneDirty] = useState(false);
        const [phoneError, setPhoneError] = useState("Поле ввода не может быть пустым");

        const [formValid, setFormValid] = useState(false);

        useEffect(() => {
            if (emailError || surnameError || nameError || phoneError) {
                setFormValid(false)
            } else {
                setFormValid(true)
            }
        }, [emailError, surnameError, nameError, phoneError]);


        const nameHandler = (e) => {
            setName(e.target.value);
            if(!e.target.value) {
                setNameError("Поле ввода не может быть пустым");
            }
            else {
                setNameError("");
            }
        }

        const surnameHandler = (e) => {
            setSurname(e.target.value);
            if(!e.target.value) {
                setSurnameError("Поле ввода не может быть пустым");
            }
            else {
                setSurnameError("");
            }
        }

        const companyHandler = (e) => {
            setCompany(e.target.value);
        }

        const emailHandler = (e) => {
            setEmail(e.target.value);
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!re.test(String(e.target.value).toLowerCase())) {
                setEmailError("Некорректный email адрес")
            } else {
                setEmailError("")
            }
        }

        const phoneHandler = (e) => {
            setPhone(e.target.value);
            const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
            if (!re.test(String(e.target.value))) {
                setPhoneError("Некорректный номер телефона")
            } else {
                setPhoneError("")
            }
        }


        const blurHandler = (e) => {
            switch (e.target.name) {
                case 'email':
                    setEmailDirty(true);
                    break;
                case 'surname':
                    setSurnameDirty(true);
                    break;
                case 'name':
                    setNameDirty(true);
                    break;
                case 'company':
                    setCompanyDirty(true);
                    break;
                case 'phone':
                    setPhoneDirty(true);
                    break;
            }
        }

        return (
            <div className='main-block4'>
                <div className='container1'>

                    <AsiaExpoIcon/>
                    <span className='text-for-label'>Зарегистрируйтесь для входа в приложение</span>
                </div>
                <div className='container-inputss'>
                    {(surnameDirty && surnameError) &&
                        <div style={{color: 'red', fontFamily: 'Muller'}}>{surnameError}</div>}
                    <Input onChange={e => surnameHandler(e)} onBlur={e => blurHandler(e)} name='surname'
                           id='input-number12' type='text' placeholder='Фамилия*' style=
                               {{
                                   width: '100%',
                                   fontSize: '14px'
                               }}/>
                    {(nameDirty && nameError) && <div style={{color: 'red', fontFamily: 'Muller'}}>{nameError}</div>}
                    <Input onChange={e => nameHandler(e)} value={name} onBlur={e => blurHandler(e)} name='name'
                           id='input-number12' type='text' placeholder='Имя*' style=
                               {{
                                   width: '100%',
                                   fontSize: '14px'
                               }}/>
                    {(emailDirty && emailError) && <div style={{color: 'red', fontFamily: 'Muller'}}>{emailError}</div>}
                    <Input onChange={e => emailHandler(e)} value={email} onBlur={e => blurHandler(e)} name='email'
                           type='text' id='input-number12' placeholder='E-mail*' style=
                               {{
                                   width: '100%',
                                   fontSize: '14px'
                               }}/>
                    {(phoneDirty && phoneError) && <div style={{color: 'red', fontFamily: 'Muller'}}>{phoneError}</div>}
                    <Input onChange={e => phoneHandler(e)} value={phone} onBlur={e => blurHandler(e)} name='phone'
                           type='text' id='input-number12' placeholder='Телефон*' style=
                               {{
                                   width: '100%',
                                   fontSize: '14px'
                               }}/>

                    <Input onChange={e => companyHandler(e)} value={company} onBlur={e => blurHandler(e)} name='company'
                           type='text' id='input-number12' placeholder='Компания' style=
                               {{
                                   width: '100%',
                                   fontSize: '14px'
                               }}/>
                </div>
                <div className='container-buttons'>
                    <Link className="button2">
                        <button disabled={!formValid}
                                onClick={submitForm}
                                className={formValid ? 'text-for-link-registration' : 'text-for-link-registration-disabled'}>Регистрация

                        </button>
                    </Link>
                </div>
            </div>
        )
    }

    const PassRecovery = () => {
        const [seconds, setSeconds] = useState(30);
        const [textColor, setTextColor] = useState("#A7AFB7");
        const [inputs, setInputs] = useState(['', '', '', '']);
        const navigate = useNavigate();

        const location = useLocation();
        const phoneNumber = location.state ? location.state.phoneNumber : "";

        const authMode = useSelector((state) => getAuthMode(state.userLogin));

        function handleKeyPress(e) {
            if (['0','1','2','3','4','5','6','7','8','9','Delete'].includes(e.key)) {
                if (e.key !== 'Delete' && isNaN(e.key)) {
                    e.preventDefault();
                } else {
                    console.log('key press handle', e);
                    //обработчик по заполнению inputs
                    // при Delete стираешь последнюю цифру, иначе добавляешь новую
                }
            } else {
                e.preventDefault();
            }
        }

        useEffect(() => {
            console.log('open confirm content', correctCode);
            // subscribe event
            document.addEventListener("keypress", handleKeyPress);
            return () => {
                // unsubscribe event
                console.log('close confirm content');
                document.removeEventListener("keypress", handleKeyPress);
            };
        }, [] );

        useEffect(() => {
            if (seconds > 0) {
                const intervalId = setInterval(() => {
                    setSeconds(prevSeconds => prevSeconds - 1);
                }, 1000);
                return () => clearInterval(intervalId);
            }
        }, [seconds]);

        const handleClick = () => {
            setSeconds(30);
            setTextColor('#A7AFB7');
            const code =   Math.floor(1000 + Math.random() * 9000);

            console.log('Новый код -', authMode.authCode)
        }

        const correctCode = authMode.authCode

        const [error, setError] = useState(false)
        const handleInput = (index, value) => {
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);

            const enteredCode = newInputs.join("");

            if (enteredCode.length === 4) {
                if (Number(enteredCode) === correctCode) {
                    setContent(<PassRecoveryModal/>)
                } else {
                    setError(true)
                    console.log('Неверный код -', enteredCode);
                    console.log(correctCode);
                }
            }
        }
        function testJump(x){
            var ml = ~~x.getAttribute('maxlength');
            if(ml && x.value.length >= ml){
                do{
                    x = x.nextSibling;
                }
                while(x && !(/text/.test(x.type)));
                if(x && /text/.test(x.type)){
                    x.focus();
                }
            }
        }
        return (
            <div className='confirm-code-main-block'>
                <AsiaExpoIcon width='300px' height='110px' />

                <div className='container-numbers'>
                    <span className='text-for-label'>Введите четырехзначный код <br/> отправленный на номер</span>
                    <span className='number-phone' id='changePhone'>{authMode.authPhone}</span>
                </div>
                <button id='btnCode' onClick={handleClick} disabled={seconds > 0}>
                    <span className='text-again'
                          style={{color: seconds === 0 ? 'black' : textColor}}>{seconds === 0 ? 'Отправить повторно' : `Отправить повторно (${seconds} сек)`}</span>
                </button>
                {(error) && <div style={{color: 'red', fontFamily: 'Muller-Bold'}}>Неверный код</div>}
                <div className='container-inputs'>
                    {
                        inputs.map((value, index) => (
                            <input
                                key={index}
                                id='input-number13'
                                maxLength='1'
                                type="text"
                                onInput={e => testJump(e.target)}
                                value={value}
                                onChange={(e) => handleInput(index, e.target.value)}
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    const PassRecoveryNumber = () => {
        return (
            <>
                <div className='top-block'>
                    <CloseIcon className="venue-modal-close-icon" onClick={() => dispatch(closeAuth())} />
                    {/*<AsiaExpoIcon style={{marginTop: '5%'}}/>*/}

                    <span className='login-title-text'>Войти или зарегистрироваться</span>
                    <span className='choice-text'>Через подтверждение по телефону</span>

                    <PhoneCountryMask
                        handleChangeField={handleChangeField}
                        extraClass="edit-input-phone"
                        countries={listPhoneCountries}
                        loading={isFetching}
                        country="RU"
                        phone={phoneNumber}
                        nameCountry={ADDRESS_PHONE_COUNTRY}
                        namePhone={ADDRESS_PHONE}
                        fluid={false}
                    />
                    <Divider fitted/>
                    <br/>
                    <div className='code-button-case'>
                        <Link className="button-confirm-code"
                        >
                            <Button disabled={phoneNumberContainsUnderscore}
                                    className={!phoneNumberContainsUnderscore ? 'text-for-link' : 'text-for-link disabled'}
                                    onClick={() => setContent(<PassRecovery/>)}
                            >Отправить код подтверждения
                            </Button>
                        </Link>
                    </div>
                </div>
            </>
        )
    }

    return (
        <Modal
            dimmer
            open={showModal}
            onClose={() => dispatch(closeAuth())}
            centered={false}
            closeOnDimmerClick
            className="user-login venue-modal"
        >
            {content || (
                <>
                    <div className='top-block'>
                        <CloseIcon className="venue-modal-close-icon" onClick={() => dispatch(closeAuth())}/>
                        {/*<AsiaExpoIcon style={{marginTop: '5%'}}/>*/}

                        <span className='login-title-text'>Войти или зарегистрироваться</span>
                        <span className='choice-text'>Через подтверждение по телефону</span>

                        <PhoneCountryMask
                            handleChangeField={handleChangeField}
                            extraClass="edit-input-phone"
                            countries={listPhoneCountries}
                            loading={isFetching}
                            country="RU"
                            phone={phoneNumber}
                            nameCountry={ADDRESS_PHONE_COUNTRY}
                            namePhone={ADDRESS_PHONE}
                            fluid={false}
                        />
                        <Divider fitted/>
                        <br/>
                        <div className='code-button-case'>
                            <Link className="button-confirm-code"
                            >
                                <Button disabled={phoneNumberContainsUnderscore}
                                        className={!phoneNumberContainsUnderscore ? 'text-for-link' : 'text-for-link disabled'}
                                        onClick={() => setContent(<ConfirmCode/>)}
                                >Отправить код подтверждения
                                </Button>
                            </Link>
                        </div>
                        <br/>
                        <div className='text-for-select'>ИЛИ</div>
                        <span className='choice-text'>Через логин или почту</span>
                    </div>
                    <Modal.Content className='venue-modal-content user-login-content'>
                        <Modal.Description>
                            {messageLoginError !== '' ? (
                                <Message
                                    negative
                                    icon="user times"
                                    header="Вы не авторизованы"
                                    content={cleanMessage(messageLoginError)}
                                />
                            ) : ''}
                            <Form error={state.emailError || state.passwordError} onSubmit={submitForm}
                                  loading={Boolean(isFetching)}>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        icon="user"
                                        iconPosition="left"
                                        placeholder="Логин или электронная почта"
                                        onChange={(e) => setState({...state, email: e.target.value})}
                                        error={state.emailError}
                                        type="email"
                                        name="login"
                                        autoComplete="on"
                                        value={state.email}
                                        id='input-numbers'
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        placeholder="Пароль"
                                        onChange={(e) => setState({...state, password: e.target.value})}
                                        error={state.passwordError}
                                        type={state.showPassword ? "text" : "password"}
                                        name="password"
                                        autoComplete="on"
                                        value={state.password}
                                        iconPosition="left"
                                        labelPosition="right"
                                        id="input-numbers">
                                        <Icon name="key"/>
                                        <input/>
                                        <Label
                                            icon={state.showPassword ? "eye" : "eye slash"}
                                            onClick={() => handleSetShowPassword(!state.showPassword)}
                                            className="user-login-toggle-show-password"
                                            id='eye-icon-id'
                                        />
                                    </Form.Input>
                                </Form.Field>
                                <div className="code-button-case">
                                    <Button
                                        disabled={state.email === '' || state.password === ''}
                                        className={state.email === '' || state.password === '' ? "text-for-link disabled" : "text-for-link"}
                                        onClick={submitForm}
                                    >
                                        Войти
                                    </Button>
                                    {/*<button*/}
                                    {/*    className="login-form-submit-button"*/}
                                    {/*    onClick={() => setContent(<Registration/>)}*/}
                                    {/*>*/}
                                    {/*    Регистрация*/}
                                    {/*</button>*/}

                                </div>

                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                </>
            )}
        </Modal>
    );
}


