import React, {useEffect, useState} from 'react';

import "./style.css"
import {Button, Divider} from "semantic-ui-react";
import {ReactComponent as ArrowIcon} from "../../icons/arrow-icon.svg";
import {BrowserView, MobileView} from "react-device-detect";
import {useSelector} from "react-redux";
import {getNews, getNewsFetching, getNewsHasMore} from "./reducer";
import {NEWS_PAGE_COUNT, fetchNews} from "./actions";

export default function Blog(props) {

    const { dispatch, navigate } = props
    // const n_news = useSelector((state) => getNews(state.news));
    // const isFetching = useSelector((state) => getNewsFetching(state.news));
    // const hasMore = useSelector((state) => getNewsHasMore(state.news));


    const news = [
        {
            text: 'Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда',
            image: 'https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
        },
        {
            text: 'Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда',
            image: 'https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
        },
        {
            text: 'Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда',
            image: 'https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
        },
        {
            text: 'Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда',
            image: 'https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
        },
        {
            text: 'Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда сюда',
            image: 'https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
        },
    ]

    const [activeButton, setActiveButton] = useState(null);
    const [content, setContent] = useState(null);

    const handleButtonClick = (index, handleChangePage) => {
        setActiveButton(index);
        handleChangePage;
    }

    useEffect(() => {
        readNews(1)
    }, []);


    const readNews = (page) => {
        dispatch(fetchNews(
            {
                page,
                per_page: NEWS_PAGE_COUNT,
                order: 'asc',
                orderby: 'id',
                event: 'ASIAEXPO 2024'
            }
        ));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        handleChangePageAPKNews();
        setActiveButton(1)
    }, [])

    const handleChangePageAPKNews = () => {
        setContent (
            <>
                <BrowserView>
                    <div className='venue-last-news'>
                        Последние новости
                    </div>
                    {news.slice(0,2).map((n) => (
                        <div className='blog-main-box'>
                                <img src={n.image} alt='Изображение отсутствует' className='blog-main-image'/>
                            <div className='blog-text-box grayblocks'>
                                <div className='blog-main-block'>
                                   <span className='blog-last-news-text'>{n.text ? n.text : ''}</span>
                                </div>
                                <Divider fitted className='blog-divider' />
                                <div className='blog-main-block'>
                                    <span className='blog-last-news-text'>{n.text ? n.text : ''}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <br/>
                    <div className='venue-last-news'>
                        Интервью с экспертами
                    </div>
                    <div className='blog-main-box'>
                        {news.map((n) => (
                        <img src={n.image} alt='Изображение отсутствует' className='blog-main-block-interview'/>
                        ))}
                    </div>
                    <br/>
                    <div className='venue-last-news'>
                        Самое актуальное
                    </div>
                    <div className='blog-main-box'>
                        <div className='blog-main-block-actual'></div>
                        <div className='blog-text-box actual'>
                            <div className='blog-text-box'>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-date-text'>15 февраля 2024 г.</div>
                            </div>
                            <div className='blog-text-box'>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-date-text'>15 февраля 2024 г.</div>
                            </div>
                            <div className='blog-text-box'>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-date-text'>15 февраля 2024 г.</div>
                            </div>
                            <div className='blog-text-box'>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-date-text'>15 февраля 2024 г.</div>
                            </div>
                            <div className='all-news'>Все новости
                                <ArrowIcon height='25px' width='25px'/>
                            </div>
                        </div>
                    </div>
                </BrowserView>

                <MobileView>
                    <div className='venue-last-news'>
                        Последние новости
                    </div>
                    <div className='blog-main-box'>
                        <div className='blog-main-image'></div>
                        <div className='blog-text-box grayblocks'>
                            <div className='blog-main-block'></div>
                            <div className='blog-main-block'></div>
                        </div>
                    </div>
                    <div className='blog-main-box'>
                        <div className='blog-text-box grayblocks'>
                            <div className='blog-main-block'></div>
                            <div className='blog-main-block'></div>
                        </div>
                        <div className='blog-main-image'></div>
                    </div>
                    <br/>
                    <div className='venue-last-news'>
                        Интервью с экспертами
                    </div>
                    <div className='blog-main-box interview'>
                        <div className='blog-main-block-interview'></div>
                        <div className='blog-main-block-interview'></div>
                        <div className='blog-main-block-interview'></div>
                        <div className='blog-main-block-interview'></div>
                    </div>
                    <br/>
                    <div className='venue-last-news'>
                        Самое актуальное
                    </div>
                    <div className='blog-main-box'>
                        <div className='blog-main-block-actual'></div>
                        <div className='blog-text-box actual'>
                            <div className='blog-text-box'>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-date-text'>15 февраля 2024 г.</div>
                            </div>
                            <div className='blog-text-box'>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-main-text'>Нужно 10 тон пшеницы туда сюда Нужно 10 тон пшеницы туда
                                    сюда
                                </div>
                                <div className='blog-date-text'>15 февраля 2024 г.</div>
                            </div>
                            <div className='all-news'>Все новости
                                <ArrowIcon height='20px' width='20px'/>
                            </div>
                        </div>
                    </div>
                </MobileView>
            </>
        )
    }

    const handleChangePageInterview = () => {
        setContent(
            <>

            </>
        )
    }

    const handleChangePageArticles = () => {
        setContent(
            <>

            </>
        )
    }

    const handleChangePageNewsAE = () => {
        setContent(
            <>

            </>
        )
    }
    return (
        <>
            <div className='venue-blog-title'>
                Блог
            </div>
            <div className='venue-blog-btns-box'>
                <Button id='venue-blog-button' className={activeButton === 1 ? 'active' : ''}
                        onClick={() => handleButtonClick(1, handleChangePageAPKNews())}>
                    Новости АПК
                </Button>
                <Button id='venue-blog-button' className={activeButton === 2 ? 'active' : ''}
                        onClick={() => handleButtonClick(2, handleChangePageInterview())}>
                    Интервью
                </Button>
                <Button id='venue-blog-button' className={activeButton === 3 ? 'active' : ''}
                        onClick={() => handleButtonClick(3, handleChangePageArticles())}>
                    Статьи
                </Button>
                <Button id='venue-blog-button' className={activeButton === 4 ? 'active' : ''}
                        onClick={() => handleButtonClick(4, handleChangePageNewsAE())}>
                    Новости ASIAEXPO
                </Button>
            </div>
            <div>{content}</div>
        </>
    )
}