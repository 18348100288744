import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import './style.css'
import translations from "../../components/LanguageModal/translations";
import {getLanguage} from "../../components/LanguageModal/reducer";
import {Base64} from "../../App";


import { ReactComponent as VectorIcon } from '../../icons/vector-icon.svg';
import {Button, Dropdown, Flag, Form, FormField, Image} from "semantic-ui-react";

import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getCompanies, getCompaniesFetching, getCompaniesHasMore, getCompaniesFilter, getSegments} from "./reducer";
import {COMPANIES_PAGE_COUNT, fetchCompanies, fetchSegments, setCountryFilter, setSegmentFilter} from "./actions";
import InfiniteView from "../../components/InfiniteView";
import ProductPager from "../../components/ProductPager";
import ModalImageGallery from "../../components/ModalImageGallery";
import { ReactComponent as DropdownIcon } from "../../icons/dropdown-icon.svg";
import {getCountries} from "../../components/AddressCard/reducer";

export default function CompanyCard(props) {

    const { company, navigate} = props;
    const segments = company.segments ? company.segments.map(elem => elem.name).join(", ") : '';
    return (
        <>
            <BrowserView>
                    <div className="main-container company-card">
                        <div className='container header-company'>
                            <img src={company.logo} className='header-company logo'/>
                            <div className='header-company text'>
                                <span className='header-company title'>{company.name}</span>
                                <span className='header-company segments'>{segments}</span>
                                <div className='header-company location-flag'>
                                    <Flag name={company.country.toLowerCase()}/>
                                    <span className='header-company location'>{company.billing.country} {company.billing.city && ', ' + company.billing.city}</span>
                                </div>
                            </div>
                        </div>
                        <div className='text company-description'>
                        <span className='company description' dangerouslySetInnerHTML={{ __html: company.excerpt }}></span>
                        </div>
                        <div className='company button-container'>
                            <Button className='company button' onClick={() => navigate(`./company/${company.id}`)}>
                                <span className='company text'>Смотреть</span>
                            </Button>
                        </div>
                    </div>
            </BrowserView>

            <MobileView>
                <div className="main-container company-card-mobile">
                    <div className='container header-company-mobile'>
                        <img src={company.logo} className='header-company logo-mobile'/>
                        <div className='header-company text-mobile'>
                            <span className='header-company title-mobile'>{company.name}</span>
                            <span className='header-company segments-mobile'>{segments}</span>
                            <div className='header-company location-flag-mobile'>
                                <Flag name={company.country.toLowerCase()}/>
                                <span className='header-company location-mobile'>{company.billing.country}, {company.billing.city}</span>
                            </div>
                        </div>
                    </div>
                    <div className='text company-description-mobile'>
                        <span className='company description-mobile' dangerouslySetInnerHTML={{ __html: company.excerpt }}></span>
                    </div>
                    <div className='company button-container-mobile'>
                        <Button className='company-button-mobile' onClick={() => navigate(`/congress-online/company/${company.id}`)}>
                            <span className='company text-mobile'>Смотреть</span>
                        </Button>
                    </div>
                </div>
            </MobileView>
        </>
    )
}

export function CongressOnline(props) {
    const {dispatch, navigate, filter, routeAction, isPageReloaded} = props;

    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const segments = useSelector((state) => getSegments(state.companies));
    const companies = useSelector((state) => getCompanies(state.companies));
    const [sortedCompanies, setSortedCompanies] = useState(null)
    const isFetching = useSelector((state) => getCompaniesFetching(state.companies));
    const hasMore = useSelector((state) => getCompaniesHasMore(state.companies));
    const filterCompanies = useSelector((state) => getCompaniesFilter(state.companies));
    const countries = useSelector((state) => getCountries(state.address));

    const arrayCountries = countries && countries.countries ? Object.values(countries.countries).map(country => ({
        'key': country.iso.toLowerCase(),
        'value': country.iso.toLowerCase(),
        'flag': country.iso.toLowerCase(),
        'text': languageString === "en" ? country.country_en : country.country_ru,
        'states': country.states
    })) : [];

    const arraySegments = segments ? Object.values(segments).map(segment => ({
        'value': segment.term_id,
        'text': languageString === "en" ? segment.name_en : segment.name,
    })) : [];

    const compFilter = filter ?? {
        countries: filterCompanies.country ?? [],
        segments: filterCompanies.segment ?? [],
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if ( routeAction === 'PUSH' || isPageReloaded ) {
            readCompanies(1);
        }
    }, [filterCompanies]);

    const readCompanies = (page) => {
        const params = {
            page,
            per_page: COMPANIES_PAGE_COUNT,
            order: 'asc',
            orderby: 'id',
            event: 'ASIAEXPO 2024'
        }

        if (compFilter && (compFilter.countries.length > 0 || compFilter.segments.length > 0)) {
            params['filter'] = Base64.encode(JSON.stringify(compFilter));
        }

        dispatch(fetchCompanies(params));

        if (page === 1) dispatch(fetchSegments({
            hide_empty: true,
            order: 'asc',
            orderby: 'name',
            lang: languageString,
        }));
    }

    const handleSegmentSelect = (e, { value }) => {
        dispatch(setSegmentFilter(value));
    };

    const handleCountrySelect = (e, { value }) => {
        dispatch(setCountryFilter(value));
    };


    useEffect(() => {
        const companiesItems = companies.map((company) => {
            return (
                <>
                    <BrowserView>
                        <CompanyCard
                            key={company.id}
                            company={company}
                            showLoader
                            {...props}
                        />
                    </BrowserView>

                    <MobileView>
                        <CompanyCard
                            key={company.id}
                            company={company}
                            showLoader
                            {...props}
                        />
                    </MobileView>
                </>


            )
        })
        setSortedCompanies(companiesItems)
    }, [companies]);

    const ModalCountry = () => {
        return (
            <>
                <Form>
                    <FormField className='filter-field'>
                        <div className='filter-container'>
                            <Dropdown
                                search
                                name='country'
                                selection
                                className='filter-input'
                                placeholder={t("country")}
                                multiple
                                clearable
                                noResultsMessage={t("notFound")}
                                options={arrayCountries}
                                value={filterCompanies.country}
                                onChange={handleCountrySelect}
                                icon={<DropdownIcon className="dropdown-icon"/>}
                            />
                        </div>
                    </FormField>
                </Form>
            </>
        )
    }

    const ModalSegment = () => {
        return (
            <>
                <Form>
                    <FormField className='filter-field'>
                        <div className='filter-container'>
                            <Dropdown
                                search
                                name='segment'
                                className='filter-input'
                                placeholder={t("segment")}
                                multiple
                                selection
                                clearable
                                noResultsMessage={t("notFound")}
                                options={arraySegments}
                                value={filterCompanies.segment}
                                onChange={handleSegmentSelect}
                                icon={<DropdownIcon className="dropdown-icon"/>}
                            />
                        </div>
                    </FormField>
                </Form>
            </>
        )
    }

    const filterDropdown = () => (
        <>
        <BrowserView>
            <div className="filter-dropdown">
                <div className='container filter-country'>
                    <ModalCountry/>
                </div>
                <div className='container filter-segment'>
                    <ModalSegment/>
                </div>
            </div>
        </BrowserView>

        <MobileView>
            <div className="filter-dropdown">
                <div className='container filter-country'>
                    <ModalCountry/>
                </div>
                <div className='container filter-segment'>
                    <ModalSegment/>
                </div>
            </div>
        </MobileView>
        </>
    );

    const loadMore = () => readCompanies(Math.round(companies.length / COMPANIES_PAGE_COUNT) + 1);
    return (
        <>
            <BrowserView>

                <div className='main-container congress-online'>
                    <span className='text-congress-online main'>{t('onlineCongress')}</span>
                    <br/>
                    <span className='text-congress-online companies'>{t('ourSuppliers')}</span>
                    {filterDropdown()}
                    {/*<InfiniteView*/}
                    {/*  dataLength={companies.length}*/}
                    {/*  nextFetch={loadMore}*/}
                    {/*  hasMore={hasMore}*/}
                    {/*  dataList={(*/}
                    {/*         <CompaniesList*/}
                    {/*             companies={companies}*/}
                    {/*             {...props}*/}
                    {/*         />*/}
                    {/*  )}*/}
                    {/*  hideEndMessage={true}*/}
                    {/*  mustAuth={false}*/}
                    {/*  isLoading={isFetching}*/}
                    {/*  loaderText="Загружаем поставщиков ..."*/}
                    {/*  zeroText="Поставщики отсутствуют"*/}
                    {/*/>*/}
                    <ProductPager
                        itemsPerPage='6'
                        rows={3}
                        columns={2}
                        nextFetch={loadMore}
                        dataLength={companies.length}
                        dataList={sortedCompanies}
                        hasMore={hasMore}
                        isLoading={isFetching}
                        width={8}
                    />
                    <br/>
                    <div className='container text-gallery' onClick={() => navigate(`/congress-online/gallery`)}>
                        <span className='text gallery'>Галерея</span>
                        <VectorIcon style={{paddingLeft: '10px', cursor: 'pointer'}}/>
                    </div>
                    <div className='container image-gallery'>
                        <PhotoCard/>
                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <div className='main-container congress-online-mobile'>
                    <span className='text-congress-online main-mobile'>{t('onlineCongress')}</span>
                    <span className='text-congress-online companies-mobile'>{t('ourSuppliers')}</span>
                    {filterDropdown()}
                    {/*<InfiniteView*/}
                    {/*    dataLength={companies.length}*/}
                    {/*    nextFetch={loadMore}*/}
                    {/*    hasMore={hasMore}*/}
                    {/*    dataList={(*/}
                    {/*        <CompaniesList*/}
                    {/*            companies={companies}*/}
                    {/*            {...props}*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*    hideEndMessage={true}*/}
                    {/*    mustAuth={false}*/}
                    {/*    isLoading={isFetching}*/}
                    {/*    loaderText="Загружаем поставщиков ..."*/}
                    {/*    zeroText="Поставщики отсутствуют"*/}
                    {/*/>*/}
                    <ProductPager
                        itemsPerPage='4'
                        nextFetch={loadMore}
                        dataLength={companies.length}
                        dataList={sortedCompanies}
                        hasMore={hasMore}
                        isLoading={isFetching}
                        width={16}
                        rows={4}
                        columns={1}
                    />
                    <br/>
                    <div className='container text-gallery-mobile'
                         onClick={() => navigate(`/congress-online/gallery`)}>
                        <span className='text gallery-mobile'>Галерея</span>
                        <VectorIcon style={{paddingLeft: '10px', cursor: 'pointer'}}/>
                    </div>
                    <div className='container image-gallery-mobile'>
                        <PhotoCard/>
                    </div>
                </div>
            </MobileView>

        </>
    )
}

function PhotoCard(props) {

    const [modalActive, setModalActive] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const modalImageGallery = () => (
        <ModalImageGallery
            open={modalActive}
            handleOpen={() => setModalActive(true)}
            handleClose={() => setModalActive(false)}
            images={[
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
            ]}
            showAsGallery={false}
            index={imageIndex}
        />);

    const onImgClick = (index) => {
        setImageIndex(index);
        setModalActive(true);
    }

    return (
        <>
            <BrowserView>
                <div className="container">
                    <div className='pictures-congress'>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(0))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(1))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(2))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(3))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(4))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(5))}/>
                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <div className="container">
                    <div className='pictures-congress-mobile'>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress-mobile' onClick={() => (onImgClick(0))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress-mobile' onClick={() => (onImgClick(1))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress-mobile' onClick={() => (onImgClick(2))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress-mobile' onClick={() => (onImgClick(3))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress-mobile' onClick={() => (onImgClick(4))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress-mobile' onClick={() => (onImgClick(5))}/>
                    </div>
                </div>
            </MobileView>
            {modalImageGallery()}
        </>
    )
}
