import { toastr } from 'react-redux-toastr';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from "../../config/config";
import {dropToken} from "../../components/UserLogin/actions";


export const REQUEST_CUSTOMER_OFFERS = 'REQUEST_CUSTOMER_OFFERS';
export const RECEIVE_CUSTOMER_OFFERS = 'RECEIVE_CUSTOMER_OFFERS';
export const RECEIVE_1PAGE_OFFERS = 'RECEIVE_1PAGE_OFFERS';
export const CREATE_CUSTOMER_OFFER = 'CREATE_CUSTOMER_OFFER';
export const OFFERS_PAGE_COUNT = 9;

export const requestCustomerOffers = (page) => ({
    type: REQUEST_CUSTOMER_OFFERS,
    page
});

export const receiveCustomerOffers = (offers) => ({
    type: RECEIVE_CUSTOMER_OFFERS,
    offers
});

export const receive1PageOffers = (offers) => ({
    type: RECEIVE_1PAGE_OFFERS,
    offers
});

export const createCustomerOffer = (offer) => ({
    type: CREATE_CUSTOMER_OFFER,
    offer
});
