import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  RECEIVE_1PAGE_PRODUCTS,
  PRODUCTS_PAGE_COUNT,
  SET_TAG_FILTER,
  CLEAR_TAG_FILTER,
  SET_SELLER_FILTER,
  CLEAR_SELLER_FILTER,
  SET_CATEGORY_FILTER,
  CLEAR_CATEGORY_FILTER,
  SET_DISPLAYORDER_FILTER,
  CLEAR_DISPLAYORDER_FILTER,

} from './actions';
import {mergeObjectArrays} from "../../configureStore";

const initialState = {
  tag: [],
  seller: [],
  category: [],
  displayOrder: [],
};


const items = (state = [], action = 0) => {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return state;
    case RECEIVE_PRODUCTS:
      return mergeObjectArrays(action.products, state);
    case RECEIVE_1PAGE_PRODUCTS:
      return action.products;
    default:
      return state;
  }
};

const hasMore = (state = false, action) => {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return true;
    case RECEIVE_PRODUCTS:
    case RECEIVE_1PAGE_PRODUCTS:
      return action.products.length >= PRODUCTS_PAGE_COUNT;
    default:
      return state;
  }
};

const isFetching = (state = {fetching: false, page: 1}, action) => {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return {fetching: true, page: action.page};
    case RECEIVE_PRODUCTS:
    case RECEIVE_1PAGE_PRODUCTS:
      return {...state, fetching: false};
    default:
      return state;
  }
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAG_FILTER:
      return {
        ...state,
        tag: action.country,
      };
    case CLEAR_TAG_FILTER:
      return {
        ...state,
        tag: [],
      };
    case SET_SELLER_FILTER:
      return {
        ...state,
        seller: [],
      };
    case CLEAR_SELLER_FILTER:
      return {
        ...state,
        seller: action.seller,
      };
    case SET_CATEGORY_FILTER:
      return {
        ...state,
        category: [],
      };
    case CLEAR_CATEGORY_FILTER:
      return {
        ...state,
        category: action.category,
      };
    case SET_DISPLAYORDER_FILTER:
      return {
        ...state,
        displayOrder: [],
      };
    case CLEAR_DISPLAYORDER_FILTER:
      return {
        ...state,
        displayOrder: action.displayOrder,
      };

    default:
      return state;
  }
};

export const getProducts = (state, category = null) => {
  if (category === null) {
    return state.items;
  }

  return state.items;
  /*
  return state.items.filter(product =>
    Array.isArray(product.categories) &&
    !_.isNil(_.find(product.categories, { id: Number(category) })));
*/
};

export const getProductsFetching = (state) => state.isFetching.fetching && state.isFetching.page === 1;
export const getProductsHasMore = (state) => state.hasMore;
export const getProductsFilter = (state) => state.filter;

export default combineReducers({
  items,
  isFetching,
  hasMore,
  filter,
});