export const REQUEST_FAVORITE_CARDS = 'REQUEST_FAVORITE_CARDS';
export const RECEIVE_FAVORITE_CARDS = 'RECEIVE_FAVORITE_CARDS';
export const RECEIVE_1PAGE_CARDS = 'RECEIVE_1PAGE_CARDS';
export const ADD_FAVORITE_CARD = 'ADD_FAVORITE_CARD';
export const REMOVE_FAVORITE_CARD = 'REMOVE_FAVORITE_CARD';
export const CARDS_PAGE_COUNT = 9;

export const requestFavoriteCards = (page) => ({
    type: REQUEST_FAVORITE_CARDS,
    page
});

export const receiveFavoriteCards = (cards) => ({
    type: RECEIVE_FAVORITE_CARDS,
    cards
});

export const receive1PageCards = (cards) => ({
    type: RECEIVE_1PAGE_CARDS,
    cards
});

export const switchFavoriteCard = (card) => (dispatch, getState) => {
    const state = getState();
    const existingIndex = state.favorites.items.findIndex(favorite => favorite.id === card.id);

    if (existingIndex !== -1) {
        dispatch({
            type: REMOVE_FAVORITE_CARD,
            index: existingIndex
        });
    } else {
        dispatch({
            type: ADD_FAVORITE_CARD,
            card
        });
    }
};
