import React, {useState} from 'react';
import {Grid, Card, Header, Button, Input, Checkbox, Divider} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './styles.css';
import {ReactComponent as CardSummaryDivider} from "../../icons/cart-summary-divider.svg";

const CardSummary = (props) => {
  const { total_products, total_shipping, show_shipping, cart_length, only_virtuals, config } = props;

  const [selectedShipping, setSelectedShipping] = useState(null);

  const handleCheckboxChange = (value) =>  {
    setSelectedShipping(selectedShipping === value ? null : value);
  }

  return (
      <Card centered className="cart-summary card-summary custom-card">
        <Card.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} className="card-delivery-row">
                Доставка по адресу
              </Grid.Column>
              <Grid.Column width={16}>
                <Checkbox
                    label={" Доставка по адресу за 5 дн."}
                    checked={selectedShipping ==='delivery'}
                    onChange={() => handleCheckboxChange('delivery')}
                    className={'card-summary-text'}
                    style={{marginBottom:'5px'}}
                />
                <br/>
                <Checkbox
                    label={" Самовывоз "}
                    checked={selectedShipping ==='sdek'}
                    onChange={() => handleCheckboxChange('sdek')}
                    className={'card-summary-text'}
                />
              </Grid.Column>
            </Grid.Row>

            {show_shipping && (
                <>
                  <Grid.Row className="card-summary-row">
                    <Grid.Column width={8}>Доставка</Grid.Column>
                    <Grid.Column textAlign="right" width={8}>
                      <div dangerouslySetInnerHTML={{ __html: total_shipping + ' ' + config.CURRENCY }} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="card-summary-row total" as={Header}>
                    <Grid.Column width={8}>Всего к оплате</Grid.Column>
                    <Grid.Column textAlign="right" width={8}>
                      <div dangerouslySetInnerHTML={{ __html: (total_products + total_shipping) + ' ' + config.CURRENCY }} />
                    </Grid.Column>
                  </Grid.Row>
                </>
            )}
            <CardSummaryDivider style={{width: '100%', marginBottom:'5px'}} />
          </Grid>
          <Link to={"/checkout-step-shipping"}>
            <Button
                className="goto-checkout"
            >
              Запросить предложение
            </Button>
          </Link>
        </Card.Content>
      </Card>
  );
};

CardSummary.defaultProps = {
  total_shipping: 0,
  show_shipping: false,
};

export default CardSummary;
