import React from 'react';
import _ from 'lodash';
import CategoryCard from './CategoryCard';
import {Link} from "react-router-dom";
import {Button} from "semantic-ui-react";

export default function CategoriesList (props) {
  if (_.isNil(props.categories) || _.isEmpty(props.categories)) {
    return (
        <div>
    <div>
      Вложенных категорий нет
    </div>
    <Link to={'/products/' + props.id}>
      <Button color="blue" compact>
        Перейти к товарам &gt;
      </Button>
    </Link>
          </div>
    );
  }
  const list = props.categories.map(element => (
    <CategoryCard
      key={element.id}
      id={element.id}
      src={_.isEmpty(element.image) ? '' : element.image.src}
      name={element.name}
      desc={element.description}
    />
  ));
  return <div>{list}</div>;
};
