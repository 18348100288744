const translations = {
    en: {
        workingInTeam:"A platform for those who are used to working in a team",
        theBestOffers:"The best offers from partners in different countries",
        globalSouth:"Convenient access to the markets of the Global South",
        availableAllCountries:"Available in all countries",
        ourProducers:"Our producers",
        onlineCongress:"Online congress",
        animalHusbandry:"Animal husbandry",
        browseOffers:"Browse offers and make safe deals online",
        agriculturalMachinery:"Agricultural Machinery",
        cropProduction:"Crop production",
        topDeals: "Top deals",
        productionProducts: "Production products",
        mainSections: "Main sections",
        company:"Company",
        aboutUs:"About Us",
        manufacturers:"Manufacturers",
        documents:"Documents",
        exhibition:"Exhibition",
        businessProgram:"Business program",
        contactUs:"Contact Us",
        contactEmail:"Contact Email",
        mailForArticles:"Mail for sending articles",
        fertilizers: "Fertilizers",
        additives: "Additives",
        plantProtection: "Plant protection",
        seeds: "Seeds",
        plantingMaterial: "Planting material",
        grainProcessingEquipment: "Grain processing equipment",
        cattle: "Cattle",
        smallHorned: "Small horned",
        birds: "Birds",
        pigs: "Pigs",
        horses: "Horses",
        aqua: "Aqua",
        beekeeping: "Beekeeping",
        equipment: "Equipment",
        disinfection: "Disinfection",
        spareParts: "Spare parts",
        machinery: "Machinery",
        fuelsLubricants: "Fuels and lubricants",
        meat: "Meat",
        dairyProducts: "Dairy products",
        groceries: "Groceries",
        seafood: "Seafood",
        oils: "Oils",
        flour: "Flour",
        feed: "Feed",
        micronutrients: "Micronutrients",
        concentrates: "Concentrates",
        nwr: "NWR",
        vetDrugs: "Veterinary drugs",
        vetEquipment: "Veterinary equipment",
        maintenanceEquipment: "Maintenance equipment",
        sprayers: "Sprayers",
        combines: "Combines",
        tiresWheels: "Tires and wheels",
        metalStructures: "Structures made of metal structures",
        catalog:'Catalog',
        technique:'Technique',
        services:'Services',
        blog:'Blog',
        offersFromBuyers:'Offers from buyers',
        exhibitors:'Exhibitors',
        contacts:'Contacts',
        price:'Price',
        typeOfAnimal:'Type of animal',
        seller:'Seller',
        category:'Category',
        byPopularity:'By popularity',
        askQuestion:'Ask a question',
        loginRegistration:'Login/Registration',
        cowFood:'Cow food',
        rabbitFood:'Rabbit food',
        pigFood:'Pig food',
        birdFood:'Bird food',
        sheepFood:'Sheep food',
        products:'products',
        allFilters:'All filters',
        ourSuppliers:'Our suppliers',
        segment: 'Category',
        country: 'Country',
        notFound: "Nothing found",
    },
    ru: {
        workingInTeam:"Платформа для тех, кто привык работать в команде",
        theBestOffers:"Лучшие предложения партнеров из разных стран",
        globalSouth:"Комфортный выход на рынки Глобального Юга ",
        availableAllCountries:"Доступно во всех странах",
        ourProducers:"Наши производители",
        onlineCongress:"Конгресс-онлайн",
        animalHusbandry:"Животноводство",
        browseOffers:"Смотрите предложения и проводите безопасные сделки онлайн",
        agriculturalMachinery:"Техника",
        cropProduction:"Растениеводство",
        topDeals: "Лучшие предложения",
        productionProducts: "Продукты производства",
        mainSections: "Основные разделы",
        company:"Компания",
        aboutUs:"О нас",
        manufacturers:"Производителям",
        documents:"Документы",
        exhibition:"Выставка",
        businessProgram:"Деловая программа",
        contactUs:"Связь с нами",
        contactEmail:"Почта для связи",
        mailForArticles:"Почта для отправки статей",
        fertilizers: "Удобрения",
        additives: "Добавки",
        plantProtection: "Защита растений",
        seeds: "Семена",
        plantingMaterial: "Посадочный материал",
        grainProcessingEquipment: "Оборудование переработка зерна",
        cattle: "КРС",
        smallHorned: "МРС",
        birds: "Птицы",
        pigs: "Свиньи",
        horses: "Кони",
        aqua: "Аква",
        beekeeping: "Пчеловодство",
        equipment: "Оборудование",
        disinfection: "Дезинфекция",
        spareParts: "Запчасти",
        machinery: "Техника",
        fuelsLubricants: "Горюче-смазочные материалы",
        meat: "Мясо",
        dairyProducts: "Молочные продукты",
        groceries: "Бакалея",
        seafood: "Морепродукты",
        oils: "Масла",
        flour: "Мука",
        feed: "Корма",
        micronutrients: "Микронутриенты",
        concentrates: "Концентраты",
        nwr: "СЗР средства защиты растений",
        vetDrugs: "Вет препарат",
        vetEquipment: "Вет оборудование",
        maintenanceEquipment: "Оборудование для содержания, доения и тд",
        sprayers: "Опрыскиватели",
        combines: "Комбайны",
        tiresWheels: "Шины и диски",
        metalStructures: "Сооружения из металлоконструкции",
        catalog:'Каталог',
        technique:'Техника',
        services:'Услуги',
        blog:'Блог',
        offersFromBuyers:'Предложения покупателей',
        exhibitors:'Экспонентам',
        contacts:'Контакты',
        price:'Цена',
        typeOfAnimal:'Вид животного',
        seller:'Продавец',
        category:'Категория',
        byPopularity:'По популярности',
        askQuestion:'Задать вопрос',
        loginRegistration:'Вход/Регистрация',
        cowFood:'Корм для коров',
        rabbitFood:'Корм для кроликов',
        pigFood:'Корм для свиней',
        birdFood:'Корм для птиц',
        sheepFood:'Корм для овец',
        products:'продуктов',
        allFilters:'Все фильтры',
        ourSuppliers:'Наши поставщики',
        segment: 'Категория',
        country: 'Страна',
        notFound: "Ничего не найдено",
    },
    zh: {
        workingInTeam:"团队协作习惯的人平台",
        theBestOffers:"来自不同国家的合作伙伴提供的最佳优惠",
        globalSouth:"全球南方市场的舒适入市",
        availableAllCountries:"向所有国家开放",
        ourProducers:"我们的制造商",
        onlineCongress:"国会-网上",
        animalHusbandry:"畜牧业",
        browseOffers:"查看优惠并在线进行安全交易",
        agriculturalMachinery:"装备",
        cropProduction:"作物生产",
        topDeals: "最佳优惠",
        productionProducts: "生产产品",
        mainSections: "主要组别",
        company:"公司",
        aboutUs:"关于公司",
        manufacturers:"致参展商",
        documents:"文件",
        exhibition:"展览及展览",
        businessProgram:"计划",
        contactUs:"联络我们",
        contactEmail:"联络电邮",
        mailForArticles:"文章的电邮地址",
        fertilizers: "肥料",
        additives: "添加剂",
        plantProtection: "植物保护",
        seeds: "种子",
        plantingMaterial: "种植材料",
        grainProcessingEquipment: "粮食加工设备",
        cattle: "大型牛类",
        smallHorned: "山羊和绵羊",
        birds: "鸟类",
        pigs: "猪",
        horses: "马匹",
        aqua: "水产养殖",
        beekeeping: "养蜂业",
        equipment: "装备",
        disinfection: "消毒",
        spareParts: "备件",
        machinery: "装备",
        fuelsLubricants: "燃料和润滑油",
        meat: "肉类",
        dairyProducts: "奶制品",
        groceries: "食品杂货",
        seafood: "海鲜",
        oils: "油类",
        flour: "面粉",
        feed: "动物饲料",
        micronutrients: "微量营养素",
        concentrates: "浓缩物",
        nwr: "植物保护产品",
        vetDrugs: "兽医药物",
        vetEquipment: "兽医设备",
        maintenanceEquipment: "养牛、挤奶等设备",
        sprayers: "喷雾器",
        combines: "联合收割机",
        tiresWheels: "轮胎和轮辋",
        metalStructures: "金属结构",
        catalog:'目录',
        technique:'设备',
        services:'服务',
        blog:'博客',
        offersFromBuyers:'买家报价',
        exhibitors:'参展商',
        contacts:'用于通信',
        price:'价格',
        typeOfAnimal:'动物种类',
        seller:'卖方',
        category:'类别',
        byPopularity:'流行的',
        askQuestion:'问一个问题',
        loginRegistration:'登入/注册',
        cowFood:'奶牛食品',
        rabbitFood:'兔子食品',
        pigFood:'猪食',
        birdFood:'鸟饲料',
        sheepFood:'羊饲料',
        products:'产品',
        allFilters:'所有过滤器',
        ourSuppliers:'我们的供应商'
    },
    ar: {
        workingInTeam:"منصة لأولئك الذين اعتادوا على العمل في فريق",
        theBestOffers:"أفضل العروض من شركاء من مختلف البلدان",
        globalSouth:"سهولة الوصول إلى أسواق الجنوب العالمي",
        availableAllCountries:"متوفر في جميع البلدان",
        ourProducers:"الشركات المصنعة لدينا",
        onlineCongress:"الكونغرس-اون لاين",
        animalHusbandry:"تربية الحيوانات",
        browseOffers:"عرض العروض وإجراء المعاملات الآمنة عبر الإنترنت",
        agriculturalMachinery:"تكنيك",
        cropProduction:"إنتاج المحاصيل",
        topDeals: "أفضل العروض",
        productionProducts: " منتجات الإنتاج",
        mainSections: "الأقسام الرئيسية",
        company:"الشركة",
        aboutUs:"معلومات عنا",
        manufacturers:"الشركات المصنعة",
        documents:"لوثائق",
        exhibition:"مَعَارِضُ",
        businessProgram:"برنامج الأعمال",
        contactUs:"اتصل بنا",
        contactEmail:"البريد الإلكتروني للاتصال",
        mailForArticles:"البريد لإرسال المقالات",
        fertilizers: "الأسمدة",
        additives: "إضافة",
        plantProtection: "وقاية النبات",
        seeds: "البذور",
        plantingMaterial: "مواد الزراعة",
        grainProcessingEquipment: "معدات تجهيز الحبوب",
        cattle: "الماشية",
        smallHorned: "الماشية ذات القرون الصغيرة",
        birds: "الطيور",
        pigs: "الخنازير",
        horses: "الخيول",
        aqua: "أكوا",
        beekeeping: "تربية النحل",
        equipment: "المعدات",
        disinfection: "التطهير",
        spareParts: "قطع الغيار",
        machinery: "تكنيك",
        fuelsLubricants: "الوقود ومواد التشحيم",
        meat: "اللحم",
        dairyProducts: "منتجات الألبان",
        groceries: "البقالة",
        seafood: "المأكولات البحرية",
        oils: "الزيوت",
        flour: "طحين",
        feed: "تغذية",
        micronutrients: "المغذيات الدقيقة",
        concentrates: "مركزات",
        nwr: "منتجات وقاية النبات",
        vetDrugs: "الأدوية البيطرية",
        vetEquipment: "المعدات البيطرية",
        maintenanceEquipment: "معدات للصيانة والحلب",
        sprayers: "البخاخ",
        combines: "حصادة",
        tiresWheels: "الإطارات والحافات",
        metalStructures: "الهياكل المعدنية",
        catalog:'كتالوج',
        technique:'المعدات',
        services:'الخدمات',
        blog:'المدونة',
        offersFromBuyers:'عروض من المشترين',
        exhibitors:'العارضين',
        contacts:'جهات الاتصال',
        price:'السعر',
        typeOfAnimal:'نوع الحيوان',
        seller:'البائع',
        category:'الفئة',
        byPopularity:'حسب الشعبية',
        askQuestion:'طرح سؤال',
        loginRegistration:'تسجيل الدخول / التسجيل',
        cowFood:'طعام البقر',
        rabbitFood:'طعام الأرانب',
        pigFood:'طعام الخنازير',
        birdFood:'الطيور',
        sheepFood:'تغذية ، تغذية الأغنام',
        products:'المنتجات',
        allFilters:'جميع المرشحات',
        ourSuppliers:'موردينا'
    },
    fr: {
        workingInTeam:"Une plate-forme pour ceux qui ont l'habitude de travailler en équipe",
        theBestOffers:"Les meilleures offres de partenaires de différents pays",
        globalSouth:"Une entrée confortable sur les marchés du Sud Mondial",
        availableAllCountries:"Disponible dans tous les pays",
        ourProducers:"Nos fabricants",
        onlineCongress:"Congrès-en ligne",
        animalHusbandry:"Élevage",
        browseOffers:"Voir les offres et effectuer des transactions sécurisées en ligne",
        agriculturalMachinery:"Matériel",
        cropProduction:"Culture des plantes",
        topDeals: "Meilleures offres",
        productionProducts: "Produits",
        mainSections: "Sections principales",
        company:"Société",
        aboutUs:"À propos de nous",
        manufacturers:"Exposants",
        documents:"Documents",
        exhibition:"Exposition",
        businessProgram:"Programme d'affaires",
        contactUs:"Contactez-nous",
        contactEmail:"Courrier à contacter",
        mailForArticles:"Mail pour envoyer des articles",
        fertilizers: "Engrais",
        additives: "Additifs",
        plantProtection: "Protection des plantes",
        seeds: "Semences",
        plantingMaterial: "Matériel de plantation",
        grainProcessingEquipment: "Équipement de traitement des céréales",
        cattle: "bovins",
        smallHorned: "petits bovins",
        birds: "Volatile",
        pigs: "porcins",
        horses: "chevaux",
        aqua: "aquiculture",
        beekeeping: "Apiculture",
        equipment: "matériel",
        disinfection: "désinfection",
        spareParts: "Rechanges",
        machinery: "materiel",
        fuelsLubricants: "Carburants et lubrifiants",
        meat: "viande",
        dairyProducts: "Produits laitiers",
        groceries: "Épicerie",
        seafood: "fruits de mer",
        oils: "Huiles",
        flour: "Farine",
        feed: "Fourrage",
        micronutrients: "Micronutriments",
        concentrates: "Concentrats",
        nwr: "produits phytopharmaceutiques",
        vetDrugs: "médicaments vétérinaires",
        vetEquipment: "équipement vétérinaire",
        maintenanceEquipment: "Équipement pour l'entretien, la traite, etc.",
        sprayers: "Pulvérisateurs",
        combines: "Moissonneuses",
        tiresWheels: "Pneus et Jantes",
        metalStructures: "structures métalliques",
        catalog:'Répertoire',
        technique:'Ingénierie',
        services:'Service',
        blog:'Blog',
        offersFromBuyers:'Suggestions d\'acheteurs',
        exhibitors:'Exposants',
        contacts:'Pour la communication',
        price:'Prix',
        typeOfAnimal:'Espèce animale',
        seller:'Vendeur',
        category:'Catégorie',
        byPopularity:'Par popularité',
        askQuestion:'Poser une question',
        loginRegistration:'Connexion / Inscription',
        cowFood:'Aliments pour vaches',
        rabbitFood:'Aliments pour lapins',
        pigFood:'Aliments pour porcs',
        birdFood:'Nourriture pour oiseaux',
        sheepFood:'Aliments pour moutons',
        products:'produits',
        allFilters:'Tous les filtres',
        ourSuppliers:'Nos fournisseurs'
    }
};

export default translations;