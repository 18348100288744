import React, { useState, useEffect } from 'react';
import './styles.css';
import {GridRow, Grid, Loader, Dimmer, GridColumn} from 'semantic-ui-react'

import { toNumber } from "lodash/lang";
import { ReactComponent as ArrowPrevIcon } from "../../icons/arrow-prev-icon.svg";
import  {ReactComponent as ArrowNextIcon } from "../../icons/arrow-nxt-icon.svg";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";

function ProductPager(props) {
    const { itemsPerPage, dataLength, dataList, nextFetch, hasMore, isLoading, width, rows, columns  } = props;
    const items = toNumber(itemsPerPage ? itemsPerPage : '3');
    const cards =  dataList;
    const [currentPage, setCurrentPage] = useState();
    const totalPages = Math.ceil( dataLength / items);
    const pagesToShow = 5;
    const pageRange = Math.min(totalPages, pagesToShow);
    const startPage = Math.max(0, Math.min(currentPage - Math.floor(pagesToShow / 2), totalPages - pageRange));

    useEffect(() => {
        setCurrentPage(0);
    }, []);
    const handleNext = () => {
        if(hasMore && dataLength <= itemsPerPage*(currentPage + 1)) {
            nextFetch();
        }
        if(dataLength <= itemsPerPage*(currentPage + 1) && !hasMore) {
            setCurrentPage(0)
        } else {
            setCurrentPage(currentPage + 1 );
        }
    };

    const router = useSelector((state) => state.router);
    const location = router.location

    const handlePrev = () => {
        if (currentPage !== 0 ) {
            setCurrentPage(currentPage - 1 );
        }
    }

    const showProducts = (page) => {
        if (typeof page !== 'number' || typeof items !== 'number' || !Array.isArray(cards)) {
            console.error('Invalid input data');
            return null;
        }
        const startIndex = page * items;
        const endIndex = startIndex + items;
        const currentPageCards = cards.slice(startIndex, endIndex);



        const products = [];
        for (let i = 0; i < rows; i++) {
            const rowItems = [];
            for (let j = 0; j < columns; j++) {
                const index = i * columns + j;
                if (index < currentPageCards.length) {
                    rowItems.push(
                        <GridColumn key={index} width={width}>
                            {currentPageCards[index]}
                        </GridColumn>
                    );
                }
            }
            products.push(
                <GridRow key={i} className='pager-row'>
                    {rowItems}
                </GridRow>
            );
        }

        return (
            <>
                {products}
            </>
        )
    }


    return (
        <div className='pager'>
                <div className='pager-container'>
                    <Grid
                        className='pager-grid'
                        centered
                        stretched
                    >
                        {showProducts(currentPage)}
                    </Grid>

                </div>
                <div className={isMobile && location.pathname === "/congress-online/gallery" ? "pager-pages-gallery" : "pager-pages"}>
                    <button className={isMobile ? "pager-btn-mobile" : "pager-btn"} onClick={handlePrev}><ArrowPrevIcon className={isMobile ? 'pager-btn-icon-mobile' : 'pager-btn-icon'} /></button>
                    <div className='pager-points-container'>
                        {Array.from({length: pageRange}, (v, i) => {
                            const pageNumber = startPage + i;
                            return (
                                <span
                                    key={i}
                                    onClick={() => setCurrentPage(pageNumber)}
                                    style={{cursor: 'pointer'}}
                                    className='pager-point-span'
                                >
                                    {
                                        pageNumber === currentPage ?
                                            <div className='pager-number pager-number-active'>{pageNumber + 1}</div>
                                            :
                                            <div className='pager-number'>{pageNumber + 1}</div>
                                    }
                                </span>
                            );
                        })}
                        {hasMore && <div className='pager-more' onClick={() =>{
                            setCurrentPage(totalPages);
                            nextFetch();
                        }}>ещё...</div>}
                    </div>
                    <button className={isMobile ? "pager-btn-mobile" : "pager-btn"} onClick={handleNext}><ArrowNextIcon className={isMobile ? 'pager-btn-icon-mobile' : 'pager-btn-icon'}  /></button>
                </div>
            {currentPage !== 0 && <div className='pager-back' onClick={() => setCurrentPage(0)}>Вернуться в начало</div>}
               <Dimmer active={isLoading} inverted>
                    <Loader active={isLoading}>Загрузка</Loader>
               </Dimmer>
        </div>
    )
}

export default ProductPager;
