import 'whatwg-fetch';
import { toastr } from 'react-redux-toastr';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from '../../config/config';
import { closeAuth } from '../NavTopBar/actions';

export const REQUEST_USER_DATA = 'REQUEST_USER_DATA';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
export const DROP_TOKEN = 'DROP_TOKEN';
export const START_AUTH_BY_PHONE = 'START_AUTH_BY_PHONE';

export const startAuthByPhone = (authPhone, authCode) => ({
  type: START_AUTH_BY_PHONE,
  data : {
    authPhone,
    authCode,
    authMode: 'sent-phone'
  }
});

export const requestUserData = () => ({
  type: REQUEST_USER_DATA,
});

export const receiveUserData = (data) => ({
  type: RECEIVE_USER_DATA,
  data,
});

export const requestToken = () => ({
  type: REQUEST_TOKEN,
});

export const receiveToken = (userName, json) => ({
  type: RECEIVE_TOKEN,
  data: {
    userName,
    userDisplayName: json.user_display_name,
    token: json.token,
    loggedIn: !!json.token,
    message: json.message,
    onDate: new Date(),
  },
});

export const dropToken = () => ({
  type: DROP_TOKEN,
  data: {
    loggedIn: false,
    message: '',
    onDate: new Date(),
  },
});

const process_api_error = (json, dispatch) => {
  dispatch(receiveUserData({ code: 401, message: json.message }));
  if (json.code === AUTH_INVALID_TOKEN) {
    dispatch(dropToken());
    toastr.error(AUTH_INVALID_MESSAGE);
  } else
  if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
    toastr.error(json.message);
  else
    toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchUserData = (token) => (dispatch) => {
  dispatch(requestUserData());
  const headers = { Authorization: 'Bearer ' + token };

  return fetch(config.API_CURRENT_USER_URL, { headers })
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveUserData(json));
    })
    .catch((ex) => {
      toastr.error('Ошибка сервера: ' + ex);
      dispatch(receiveUserData({ code: 401, message: ex }));
    });
};

export const fetchToken = (userName, userPassword) => (dispatch) => {
  dispatch(requestToken());

  return fetch(config.API_TOKEN_URL + '?username=' + userName + '&password=' + userPassword, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveToken(userName, json));
      if (json.token) {
        toastr.success('Добро пожаловать, ' + json.user_display_name + '!');
        dispatch(fetchUserData(json.token));
        dispatch(closeAuth());
      }
    })
    .catch((ex) => {
      dispatch(receiveToken(userName, { code: 401, message: ex }));
    });
};

export const updateUserData = (token, user_id, query, silent = false) => (dispatch) => {
  dispatch(requestUserData());

 const headers =  token ? { Authorization: 'Bearer ' + token } : {};
 let url = config.API_UPDATE_USER_DATA_URL + user_id;

  console.log('updateUserData action', user_id, query, url, token);

  const formData = new FormData();
  formData.append('query', query);

  return fetch(url, { method: 'post', headers, body: formData, })
      .then((response) => response.json())
      .then((json) => {
        console.log('updateUserData responce', json);
        if (json.code === 200) {
          dispatch(receiveUserData(json.data));
          if (!silent)
            toastr.success(`Изменения сохранены`);
        } else {
          process_api_error(json, dispatch);
        }
      })
      .catch( (error_message) => {
        console.log('ошибка', error_message);
        toastr.error('Ошибка запроса: ' + String(error_message))
      });
};

export const putTicket = (token, ticketData) => (dispatch) => {

    const headers = {Authorization: 'Bearer ' + token};
    let url = config.API_CREATE_USER_TICKET + '?' +
        Object.keys(ticketData)
            .map((k) => k + '=' + encodeURIComponent(ticketData[k]))
            .join('&');

    return fetch(url, {method: 'post', headers})
        .then((response) => response.json())
        .then((json) => {
            if (json.status === 200) {
            } else {
                process_api_error(json, dispatch);
                console.warn('Ошибка создания запроса putTicket', url, ticketData, json);
            }
        })
        .catch((ex) => {
            console.error('Ошибка запроса: ' + String(ex));
        });
}