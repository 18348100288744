import {createStore, applyMiddleware, combineReducers} from 'redux';
import { persistCombineReducers, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { createReduxHistoryContext } from "redux-first-history";
import { reducer as toastr } from 'react-redux-toastr';
import thunk from 'redux-thunk';
import {createHashHistory} from 'history';

import categories from './views/Categories/reducer';
import products from './views/Products/reducer';
import reviews from './components/Reviews/reducer';
import variations from './components/Variations/reducer';
import search from './views/Search/reducer';
import navtopbar from './components/NavTopBar/reducer';
import navbottombar from './components/NavBottomBar/reducer';
import userLogin from './components/UserLogin/reducer';
import userLocation from './components/GeoLocation/reducer';
import address from "./components/AddressCard/reducer";
import cart from "./views/Cart/reducer";
import companies from "./views/CongressOnline/reducer"
import languageReducer from "./components/LanguageModal/reducer";
import offers from "./views/CustomerOffers/reducer";
import favorites from "./views/Favorite/reducer";


export const mergeObjectArrays = (fromArray, toArray) => {

    let newArray = structuredClone(toArray);
    _.forEach(fromArray, (element) => {
        const index = newArray.findIndex(x => x.id === element.id);
        if (index !== -1)
            newArray[index] = element;
        else
            newArray.push(element);
    });

    return newArray;
}

const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
        history: createHashHistory(),
        savePreviousLocations: 2,
});

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: [
        'navtopbar',
        'navbottombar',
        'toastr',
        'router',
  ],
   //debug: true,
};

const rootReducer = persistCombineReducers(rootPersistConfig, {
  categories: persistReducer(
    {
      key: 'categories',
      storage,
      blacklist: ['isFetching', 'hasMore'],
    },
    categories,
  ),
  products: persistReducer(
    {
      key: 'products',
      storage,
      blacklist: ['isFetching', 'hasMore'],
    },
    products,
  ),
  reviews: persistReducer(
    {
      key: 'reviews',
      storage,
      blacklist: ['isFetching', 'hasMore'],
    },
    reviews,
  ),
  variations: persistReducer(
    {
      key: 'variations',
      storage,
      blacklist: ['isFetching'],
    },
    variations,
  ),
  navtopbar,
  navbottombar,
  search: persistReducer(
      {
          key: 'search',
          storage,
          blacklist: ['isFetching', 'hasMore'],
      },
      search,
  ),
  toastr,
  userLogin: persistReducer(
    {
      key: 'userLogin',
      storage,
    },
    userLogin,
  ),
  userLocation: persistReducer(
    {
      key: 'userLocation',
      storage,
    },
    userLocation,
  ),
  address: persistReducer(
        {
            key: 'address',
            storage,
        },
        address,
    ),
    cart: persistReducer(
        {
            key: 'cart',
            storage,
        },
        cart,
    ),
  companies: persistReducer(
        {
            key: 'companies',
            storage,
            blacklist: ['isFetching', 'hasMore'],
        },
        companies,
    ),
    language: persistReducer(
        {
            key: 'language',
            storage,
        },
        languageReducer,
    ),
    offers: persistReducer(
        {
            key:'offers',
            storage,
            blacklist: ['isFetching', 'hasMore'],
        },
        offers,
    ),
    favorites: persistReducer(
        {
            key:'favorites',
            storage,
            blacklist: ['isFetching', 'hasMore'],
        },
        favorites,
    ),
  router: routerReducer,
});


export const store = createStore(
    rootReducer,
    undefined,
    applyMiddleware(thunk, routerMiddleware)
);

export const routerHistory = createReduxHistory(store);
export const persistor = persistStore(store);
