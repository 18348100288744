


const Photo = 'https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg';
const Photo2 = 'https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_3-scaled.jpeg';

export const photos = [
    {
        id: 0,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 1,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 2,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 3,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 4,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 5,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 6,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 7,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 8,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 9,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 10,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 11,
        original: Photo,
        thumbnail: Photo
    },
]



