import React, { useState, useEffect, useRef } from 'react';
import './gradientBox.css';
import { Image } from "semantic-ui-react";
import { ReactComponent as ControlButtonIcon } from "../../icons/control-button-icon.svg";
import { ReactComponent as ControlButtonIconMobile } from "../../icons/control-button-icon-mobile.svg";
import { isMobile, isBrowser } from "react-device-detect";

const GradientBox = ({ autoPlayInterval = 6000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const timerRef = useRef(null);

    const images = [
        'https://cms.asiaexpo.space/wp-content/uploads/2024/08/Group-10280.png',
        'https://cms.asiaexpo.space/wp-content/uploads/2024/08/Group-10280.png',
    ];

    if (images.length === 0) return null;

    const resetAutoPlay = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);  // Очистка текущего таймера
        }
        timerRef.current = setInterval(handleNext, autoPlayInterval);  // Установка нового таймера
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        resetAutoPlay();  // Сброс таймера при ручном перелистывании
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        resetAutoPlay();  // Сброс таймера при ручном перелистывании
    };

    useEffect(() => {
        resetAutoPlay();  // Устанавливаем таймер при монтировании компонента

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);  // Очистка таймера при размонтировании компонента
            }
        };
    }, []);

    const imageStyle = isMobile ? { position: 'relative', padding: '0px', maxWidth: '100%' } : {};

    return (
        <div className="gradient-box">
            <div className="controls">
                <button onClick={handlePrev} className="control-button">
                    {isMobile ? (
                        <ControlButtonIconMobile className="control-button-icon" style={{ transform: 'rotate(180deg)' }} />
                    ) : isBrowser ? (
                        <ControlButtonIcon className="control-button-icon" />
                    ) : null}
                </button>
                <button onClick={handleNext} className="control-button">
                    {isMobile ? (
                        <ControlButtonIconMobile className="control-button-icon" />
                    ) : isBrowser ? (
                        <ControlButtonIcon className="control-button-icon" style={{ transform: 'rotate(180deg)' }} />
                    ) : null}
                </button>
            </div>
            <div className="gradient-box-content">
                <div className="image-slider" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {images.map((image, index) => (
                        <div className="image-container" key={index}>
                            <Image
                                src={image}
                                alt="content"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </div>
                    ))}
                </div>
                <div className="pagination">
                    {images.map((_, index) => (
                        <span
                            key={index}
                            className={`page-dot ${index === currentIndex ? 'active' : ''}`}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GradientBox;