import React, { useState, useEffect } from 'react';
import './styles.css'
import { useSelector } from "react-redux";
import {Dimmer, Modal, Loader, FormField, Input} from "semantic-ui-react";
import {PartySuggestions, AddressSuggestions, EmailSuggestions, FioSuggestions} from "react-dadata";
import 'react-dadata/dist/react-dadata.css';

import PhoneCountryMask from "../../components/AddressCard/PhoneCountryMask";
import {
    ADDRESS_COUNTRY,
    ADDRESS_PHONE,
    ADDRESS_PHONE_COUNTRY, fetchCounties, setAddressFields,
} from '../../components/AddressCard/actions';
import {getAddressFields, getCountries, isCountriesFetching} from '../../components/AddressCard/reducer';
import {updateUserData} from "../../components/UserLogin/actions";
import {dropToken} from "../../components/UserLogin/actions";
import {DADATA_API_KEY} from "../../config/secrets";
import _ from "lodash";
import { ReactComponent as CloseIcon} from "../../icons/close-icon.svg";

export default function EditProfile(props) {
    const {dispatch, navigate} = props;

    const [location, setLocation] = useState('profile');
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [isEditOrg, setIsEditOrg] = useState(false);
    const [isExitOpen, setIsExitOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const userData = useSelector(state => state.userLogin.userData);
    const [formData, setFormData ] = useState({
        first_name: userData.billing.first_name,
        last_name: userData.billing.last_name,
        email: userData.billing.email,
        phone: userData.billing.phone,
        phone_country: userData.billing.phone_country,
        postcode: userData.billing.postcode,
        company: userData.billing.company})


    const fields = useSelector((state) => getAddressFields(state.address));
    const isFetching = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));

    const [errors, setErrors] = useState({});
    const isFormValid = Object.values(errors).every(error => error === '');
    const [statusButton, setStatusButton] = useState(isFormValid);

    let listPhoneCountries = [];
    if (!isFetching && countries && countries.countries) {
        listPhoneCountries = Object.values(countries.countries).map((element, index) => (
            {
                key:  index,
                text: element.country_ru,
                flag: _.lowerCase(element.iso),
                value: element.iso,
                className: "dropdown-item",
                phone_mask: element.phone_mask,
            }
        ));
    }
    const phoneMask = listPhoneCountries.find(item => item.value === formData.phone_country)?.phone_mask;

    useEffect(() => {
        validateFields();
        if (!countries || !countries.countries || !countries.countries[fields[ADDRESS_COUNTRY]]) {
            dispatch(fetchCounties());
        }
        mountDirectField([
            {name: 'countryPhoneCode', value: formData.phone_country},
            {name: 'phone', value: formData.phone}
        ]);
    }, []);

    useEffect(() => {
        setStatusButton(!isFormValid)
    }, [errors, isFormValid]);

    console.log(formData)

    const updateField =  () => {
        formData.phone_country = fields[ADDRESS_PHONE_COUNTRY];
        formData.phone = fields[ADDRESS_PHONE];
        dispatch(setAddressFields(fields));
    }

    const formFields = [
        'first_name',
        'last_name',
        'email',
        'phone',
    ];

    const validateFields = () => {
        formFields.forEach(field => {
            validate(field, formData[field]);
        })
    }

    const mountDirectField = (data) => {
        data.forEach((element) => {
            fields[element.name] = element.value;
        });
        updateField();
    }

    const setDirectField = (name, data) => {
        if (name === 'countryPhoneCode') {
            fields['phone'] = '';
        }
        fields[name] = data;
        updateField();
    }

    const handleSubmit = () => {
        if (isEditProfile) {
            setIsEditProfile(false);
            setIsLoading(true);
            const query = {
                billing: {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    email: formData.email,
                    phone: formData.phone,
                    phone_country: formData.phone_country,
                    postcode: formData.postcode
                }
            }
            dispatch(updateUserData(props.token, formData.id, query, false))
                .then(() => {
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setIsEditProfile(true);
        }
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
        validate(e.target.name, e.target.value);
        console.log(formData)
    }

    const handleCompanyChange = (value) => {
        setFormData({
            ...formData,
            company: value.value,
        })
    }

    const handleCompanyAddressChange = (value) => {
        setFormData({
            ...formData,
            company_address: value.value,
        })
    }

    const handleCompanyEmailChange = (value) => {
        setFormData({
            ...formData,
            company_email: value.value,
        })
        validate('company_email', value.value)
    }

    const handleNameChange = (value) => {
        setFormData({
            ...formData,
            name: value
        })
        validate('first_name', value);
    }

    const handleLastNameChange = (value) => {
        setFormData({
            ...formData,
            last_name: value
        })
        validate('last_name', value);
    }

    const handleEmailChange = (value) => {
        setFormData({
            ...formData,
            email: value
        })
        validate('email', value);
    }


    const isValidEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const validate = (fieldName, value) => {
        let fieldErrors = {};
        if (fieldName === 'first_name') {
            if (!value) {
                fieldErrors = { [fieldName]: 'Имя обязательно для заполнения'};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }
        if (fieldName === 'last_name') {
            if (!value) {
                fieldErrors = { [fieldName]: 'Фамилия обязательна для заполнения'};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }
        if (fieldName === 'email') {
            if (!value) {
                fieldErrors = { [fieldName]: 'Почта обязательна для заполнения'};
            } else if (!isValidEmail(value)) {
                fieldErrors = { [fieldName]: 'Некорректный email'};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }
        if (fieldName === 'phone') {
            if (formData.phone === '') {
                fieldErrors = { [fieldName]: 'Номер телефона обязателен для заполнения'};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }
        if (fieldName === 'company_email') {
            if (!isValidEmail(value)) {
                fieldErrors = { [fieldName]: 'Некорректный email'};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }



        setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors }));
    }

    const returnProfile = () => {
        return (
            <div className='edit-profile'>
                <div className='edit-segment'>
                    <form className='edit-form'>
                        <formfield className='edit-formfield'>
                            <label className='edit-label'>Мои данные</label>
                            <FioSuggestions
                                token={DADATA_API_KEY}
                                inputProps={{
                                    className: 'edit-input',
                                    name: 'first_name',
                                    placeholder: 'Имя',
                                    onChange: handleChange,
                                    disabled: !isEditProfile
                                }}
                                count='3'
                                defaultQuery={formData.first_name}
                                onChange={handleNameChange}
                            />
                            {errors.first_name && isEditProfile && <span className='edit-error'>{errors.first_name}</span>}
                            <FioSuggestions
                                token={DADATA_API_KEY}
                                inputProps={{
                                    className: 'edit-input',
                                    name: 'last_name',
                                    placeholder: 'Фамилия',
                                    onChange: handleChange,
                                    disabled: !isEditProfile,
                                }}
                                count='3'
                                defaultQuery={formData.last_name}
                                onChange={handleLastNameChange}
                            />
                            {isEditProfile && <span className='edit-error'>{errors.last_name}</span>}
                            <EmailSuggestions
                                token={DADATA_API_KEY}
                                inputProps={{
                                    className: 'edit-input',
                                    name: 'email',
                                    placeholder: 'Почта',
                                    onChange: handleChange,
                                    disabled: !isEditProfile
                                }}
                                count='3'
                                defaultQuery={formData.email}
                                onChange={handleEmailChange}
                            />
                            {errors.email && isEditProfile && <span className='edit-error'>{errors.email}</span>}
                            {isEditProfile ?
                                <div>
                                    <PhoneCountryMask
                                        extraClass='edit-input'
                                        name='phone'
                                        countries={listPhoneCountries}
                                        country={formData.phone_country}
                                        loading={isFetching}
                                        phone={formData.phone}
                                        phoneMask={phoneMask}
                                        handleChangeField={setDirectField}
                                        nameCountry={ADDRESS_PHONE_COUNTRY}
                                        namePhone={ADDRESS_PHONE}
                                        fluid={true}
                                        placeholder='Номер телефона'
                                    />
                                    {errors.phone && isEditProfile && <span className='edit-error'>{errors.phone}</span>}
                                </div>
                                :
                                <input className='edit-input' disabled value={formData.phone}
                                       placeholder='Номер телефона'/>}
                            <input className='edit-input' disabled={!isEditProfile} name='postcode'
                                   onChange={handleChange} value={formData.postcode} placeholder='Индекс и адрес'/>
                        </formfield>
                    </form>
                    <div className='edit-btn-container'>
                        <button onClick={handleSubmit}
                                disabled={statusButton}
                                className={'edit-btn-edit' + (statusButton ? ' edit-btn-edit_active' : '')}>{isEditProfile ? 'Сохранить' : 'Редактировать'}
                        </button>

                        <Modal
                            className='edit-popup-exit'
                            open={isExitOpen}
                            trigger={
                                <button onClick={() => setIsExitOpen(true)} className='edit-btn-exit'>Выйти</button>
                            }
                            onClose={() => setIsExitOpen(false)}
                            centered
                        >
                            <Modal.Content>
                                <CloseIcon className="edit-exit-close-icon" onClick={() => setIsExitOpen(false)}/>
                                <h2 className='edit-exit-title'>Вы уверены, что хотите выйти из Личного кабинета?</h2>
                                <div className='edit-exit-btn-container'>
                                    <button onClick={() => {
                                        dispatch(dropToken())
                                        navigate('/')
                                    }} className='edit-exit-popup-btn edit-exit-btn-yes'>Да</button>
                                    <button onClick={() => setIsExitOpen(false)} className='edit-exit-popup-btn edit-exit-btn-no'>Нет</button>
                                </div>
                            </Modal.Content>
                        </Modal>
                    </div>
                </div>
                <div>
                    <div className='edit-segment'>
                        <form className='edit-form'>
                            <formfield className='edit-formfield'>
                                <label className='edit-label'>Данные организации</label>
                                <PartySuggestions
                                    token={DADATA_API_KEY}
                                    inputProps={{
                                        className: 'edit-input',
                                        name: 'company',
                                        placeholder: 'Наименование',
                                        onChange: handleChange,
                                        disabled: !isEditOrg
                                    }}
                                    count='3'
                                    defaultQuery={formData.company}
                                    onChange={handleCompanyChange}
                                />
                                <EmailSuggestions
                                    token={DADATA_API_KEY}
                                    inputProps={{
                                        className: 'edit-input',
                                        name: 'company_email',
                                        placeholder: 'Почта',
                                        onChange: handleChange,
                                        disabled: !isEditOrg
                                    }}
                                    count='3'
                                    defaultQuery={formData.company_email}
                                    onChange={handleCompanyEmailChange}
                                />
                                {errors.company_email && isEditOrg && <span className='edit-error'>{errors.company_email}</span>}
                                <AddressSuggestions
                                    token={DADATA_API_KEY}
                                    inputProps={{
                                        className: 'edit-input',
                                        name: 'company_address',
                                        placeholder: 'Адрес',
                                        onChange: handleChange,
                                        disabled: !isEditOrg
                                    }}
                                    count='3'
                                    defaultQuery={formData.company_address}
                                    onChange={handleCompanyAddressChange}
                                />
                                <input className='edit-input' disabled={!isEditOrg} value={formData.company_site} onChange={handleChange} name='company_site' placeholder='Ссылка на сайт'/>
                                <input className='edit-input' disabled={!isEditOrg} value={formData.company_segment} onChange={handleChange} name='company_segment' placeholder='Сегмент'/>
                            </formfield>
                        </form>
                        <div className='edit-btn-container' id='edit-btn-container-2'>
                            <button onClick={() => setIsEditOrg(!isEditOrg)}  className='edit-btn-edit'>{isEditOrg ? 'Сохранить' : 'Редактировать'}</button>
                        </div>
                    </div>
                </div>
                <Dimmer>
                    <Loader active={isLoading}/>
                </Dimmer>
            </div>
        )
    }

    const returnHistory = () => {
        return (
            <div>
                <h1 style={{textAlign: "center", marginTop: '10px'}}>История заказов</h1>
            </div>
        )
    }

    return (
        <div className='edit-view'>
            <h1 className='edit-title'>Личный кабинет</h1>
            <nav className='edit-nav'>
                <ul className='edit-nav-list'>
                    <li
                        className={'edit-nav-list-item' + (location === 'profile' ? ' edit-nav-list-item_active' : '')}
                        onClick={() => setLocation('profile')}
                    >
                        <p className='edit-nav-link'>Профиль</p>
                    </li>
                    <li
                        className={'edit-nav-list-item' + (location === 'offers' ? ' edit-nav-list-item_active' : '')}
                        onClick={() => setLocation('offers')}
                    >
                        <p className='edit-nav-link'>Мои предложения</p>
                    </li>
                    <li
                        className={'edit-nav-list-item' + (location === 'forum' ? ' edit-nav-list-item_active' : '')}
                        onClick={() => setLocation('forum')}
                    >
                        <p className='edit-nav-link'>Форум</p>
                    </li>
                    <li
                        className={'edit-nav-list-item' + (location === 'history' ? ' edit-nav-list-item_active' : '')}
                        onClick={() => setLocation('history')}
                    >
                        <p className='edit-nav-link'>История заказов</p>
                    </li>
                </ul>
            </nav>
            <div className='edit-content'>
                {location ? location === 'profile' ? returnProfile() : null : null}
                {location ? location === 'history' ? returnHistory() : null : null}
            </div>
        </div>
    )
}
